"use client";

import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { StyleFunction } from "leaflet";

import { BivariatePoint } from "./util/types";
import {
  MhcFeatureCollection,
  MhcGeoJsonFeatureProperty,
  MhcLocation,
  MhcLocationFragment,
  MhcTimeSeriesGranularityEnum
} from "graphqlApi/types";

import { bringGeoJsonFeatureToTheTop } from "../GeoMap/util/bringGeoJsonFeatureToTheTop";

import { GeoMap, GeoMapProps } from "../GeoMap";
import {
  basicPolygonFeatureStyle,
  missingPolygonFeatureStyle,
  selectedPolygonFeatureStyle
} from "../GeoMap/mapStyles";
import { GeoMapPopover, GeoMapPopoverResult } from "../GeoMap/Popover/GeoMapPopover";

interface BivariateMapResult extends GeoMapPopoverResult {
  result?: never;
}

export interface BivariateMapProps {
  width: string;
  height: string;
  latestXYDataDates?: { x: string; y: string };
  granularities?: { x: MhcTimeSeriesGranularityEnum; y: MhcTimeSeriesGranularityEnum };
  geoJSON: MhcFeatureCollection;
  locationReference: { [id: string]: MhcLocation | MhcLocationFragment };
  valueMap: { [id: string]: BivariatePoint };
  xAxisResult: BivariateMapResult;
  yAxisResult: BivariateMapResult;
  featureColorFunction: (xValue: number, yValue: number) => string | undefined;
  selectedBivariatePoint?: BivariatePoint | undefined;
  setSelectedBivariatePoint: Dispatch<SetStateAction<BivariatePoint | undefined>>;
  mapPopoverButtonHrefCreator?: (id: string) => string;
}

export const BivariateMap: React.FC<BivariateMapProps> = ({
  width,
  height,
  granularities,
  latestXYDataDates,
  geoJSON,
  valueMap,
  xAxisResult,
  yAxisResult,
  featureColorFunction,
  selectedBivariatePoint,
  setSelectedBivariatePoint,
  locationReference,
  mapPopoverButtonHrefCreator
}) => {
  const [selectedId, setSelectedId] = useState<string | undefined>();
  const [_geoJSON, setGeoJSON] = useState<MhcFeatureCollection | undefined>(geoJSON);

  useEffect(() => {
    if (selectedId === undefined) {
      return;
    }
    setGeoJSON((geoJson) => {
      return bringGeoJsonFeatureToTheTop(selectedId, geoJson);
    });
  }, [selectedId, setGeoJSON]);

  useEffect(() => {
    if (selectedId === undefined) {
      setSelectedBivariatePoint(undefined);
      return;
    }
    const bivariatePoint = valueMap[selectedId];
    setSelectedBivariatePoint(bivariatePoint);
  }, [selectedId, setSelectedBivariatePoint, valueMap]);

  const featureStyle: GeoMapProps["featureStyle"] = useCallback(
    (feature: Parameters<StyleFunction<MhcGeoJsonFeatureProperty>>[0]) => {
      let base = {
        ...(feature?.properties.id === selectedId
          ? selectedPolygonFeatureStyle
          : basicPolygonFeatureStyle)
      };
      const values = valueMap[feature?.properties.id ?? ""];
      if (values?.xAxis !== undefined && values?.yAxis !== undefined) {
        const color = featureColorFunction?.(values?.xAxis, values?.yAxis);
        if (color !== undefined) {
          base = {
            ...base,
            fillColor: color
          };
          if (feature?.properties.id === selectedId) {
            base["fillOpacity"] = 1.0;
          }
        } else {
          base["fillColor"] = missingPolygonFeatureStyle.fillColor;
        }
      } else {
        base["fillColor"] = missingPolygonFeatureStyle.fillColor;
      }
      return base;
    },
    [featureColorFunction, selectedId, valueMap]
  );

  const investigateMapPopoverLocation = useMemo(() => {
    if (selectedId === undefined) {
      return undefined;
    }
    const location = locationReference[selectedId];
    if (location !== undefined) {
      return location.name;
    }
    return selectedId;
  }, [locationReference, selectedId]);

  const investigateMapInfoBox = useMemo(() => {
    const color = (() => {
      if (
        selectedBivariatePoint?.xAxis !== undefined &&
        selectedBivariatePoint?.yAxis !== undefined
      ) {
        const placeholder = featureColorFunction?.(
          selectedBivariatePoint?.xAxis,
          selectedBivariatePoint?.yAxis
        );
        if (placeholder) {
          return placeholder;
        }
      }
      return undefined;
    })();

    return (
      <GeoMapPopover
        locationId={selectedId}
        locationName={investigateMapPopoverLocation}
        closePopoverFunction={() => setSelectedId(undefined)}
        results={
          selectedId !== undefined && selectedBivariatePoint !== undefined
            ? [
                {
                  ...xAxisResult,
                  result: selectedBivariatePoint?.xAxis,
                  colorCode: color,
                  date: latestXYDataDates?.x,
                  granularity: granularities?.x
                },
                {
                  ...yAxisResult,
                  result: selectedBivariatePoint?.yAxis,
                  colorCode: color,
                  date: latestXYDataDates?.y,
                  granularity: granularities?.y
                }
              ]
            : undefined
        }
        buttonTitle="Go to census tract report"
        buttonCallback={mapPopoverButtonHrefCreator ? mapPopoverButtonHrefCreator : undefined}
        allowEmpty={selectedId !== undefined}
      />
    );
  }, [
    latestXYDataDates,
    granularities,
    selectedId,
    selectedBivariatePoint,
    xAxisResult,
    yAxisResult,
    featureColorFunction,
    investigateMapPopoverLocation,
    mapPopoverButtonHrefCreator
  ]);

  return (
    <>
      {_geoJSON && (
        <GeoMap
          forceLoading={false}
          geoJSON={_geoJSON}
          featureStyle={featureStyle}
          onFeatureClick={setSelectedId}
          width={width}
          height={height}
          infoBox={investigateMapInfoBox}
          selectedDataIdentifier={
            [
              xAxisResult?.statIdentifier?.id ?? "UNKNOWN",
              yAxisResult?.statIdentifier?.id ?? "STATS"
            ].join(":") as string
          }
        />
      )}
    </>
  );
};
