import { Box, Link, Typography } from "@mui/material";

export interface BivariateParagraphProps {
  title?: string;
  paragraph?: string;
  aboutTheDataTitle?: string;
  aboutTheDataHref?: string;
}

export const BivariateParagraph: React.FC<BivariateParagraphProps> = ({
  title,
  paragraph,
  aboutTheDataTitle = "About the data",
  aboutTheDataHref
}) => {
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ width: "100%" }}>
        {aboutTheDataTitle && aboutTheDataHref && (
          <Link href={aboutTheDataHref}>
            <Typography variant="body1" fontWeight={600} color="brand.main" sx={{ mb: 1 }}>
              {aboutTheDataTitle}
            </Typography>
          </Link>
        )}
        {title && (
          <Typography variant="h4" fontWeight={400} color="light.primary">
            {title}
          </Typography>
        )}
        {paragraph && (
          <Typography
            variant="body2"
            sx={{ mt: "8px", whiteSpace: "pre-wrap" }}
            color="light.primary"
          >
            {paragraph}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
