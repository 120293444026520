"use client";

import Link from "next/link";
import { Box, Link as MuiLink, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { DataStory } from "common/content/dataStories";

import { FeatureCard, FeatureCardProps } from "layout/card/FeatureCard";
import { PillButton } from "component/button/PillButton";

type DataStoryCard = DataStory & {
  showDescription?: boolean;
  linkAsButton?: boolean;
  linkIsInline?: boolean;
  variant?: FeatureCardProps["variant"];
  imageObjectPosition?: FeatureCardProps["imageObjectPosition"];
  cardAsLink?: boolean;
};

const DataStoryCard: React.FC<DataStoryCard> = ({
  context,
  datePublished,
  description,
  image,
  showDescription,
  title,
  url,
  linkAsButton = true,
  linkIsInline = false,
  variant = "large",
  cardAsLink = false,
  imageObjectPosition
}) => {
  const { breakpoints } = useTheme();
  const small = useMediaQuery(breakpoints.down("sm"));
  const isSmall = variant === "small";
  let link = null;
  if (url) {
    link = (
      <MuiLink component={Link} href={url} aria-label={`Read the ${title} data story`}>
        Read the data story
      </MuiLink>
    );
  }
  return (
    <FeatureCard
      href={url}
      key={title}
      component={cardAsLink ? "a" : undefined}
      src={image}
      alt={title}
      vertical={small}
      imageHeight={isSmall ? 135 : undefined}
      variant={variant}
      contentPadding={isSmall ? 2 : undefined}
      imageObjectPosition={imageObjectPosition}
      sx={{
        ...(isSmall
          ? { borderWidth: 1, borderColor: "borders.light", borderStyle: "solid" }
          : undefined)
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography sx={{ mb: 0.5 }} variant="body2">
          {context}
        </Typography>
        <Typography sx={{ mb: 1 }} component="span" variant="h4">
          {title}
        </Typography>
        {!isSmall && datePublished && (
          <Typography sx={{ mb: 1 }} variant="caption">
            Date published: {datePublished}
          </Typography>
        )}
        {showDescription && (
          <Typography variant="body1">
            {description}
            {!linkAsButton && linkIsInline && url && <> {link}</>}
          </Typography>
        )}
        {!linkAsButton && !linkIsInline && link}
        {!isSmall && linkAsButton && url && (
          <PillButton
            variant="contained"
            color="brand"
            sx={{
              mt: !small ? 2 : 2,
              mr: "auto",
              borderRadius: "25px"
            }}
            href={url}
            aria-label={`Read the ${title} story`}
          >
            Read the story
          </PillButton>
        )}
      </Box>
    </FeatureCard>
  );
};

export default DataStoryCard;
