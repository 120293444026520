export const DEFAULT_IMAGE_SIZES = "(min-width: 900px) 700px, 100vw";

export const KPITargetExceptions: Record<string, Record<string, unknown>> = {
  PRESCRIPTION_PILL_ABUSE_YRBS_CRUDE_RATE: {
    hideLongStatement: true
  }
};

export const ANIMATED_MAP_METHODOLOGY = `The methodology to generate the heat maps of disease rates across Delaware also ensures individual locations cannot be identified. `;

export const privacyStatementText = (addMapDetails = false) =>
  `In releasing this information, Delaware aims to provide information that can be helpful to local communities as they work to improve public health. However, the protection of the confidentiality of our citizens is of paramount importance. To that end, counts of less than 11 are not presented. ${
    addMapDetails ? ANIMATED_MAP_METHODOLOGY : ""
  }
An outside expert has reviewed the platform to ensure it complies with the HIPAA privacy rule (45 CFR 164.§514(b)).`;
