"use client";

import { ChangeEvent, useEffect, useMemo } from "react";
import { Grid } from "@mui/material";

import { MappedDropdownOption } from "../charts/Investigate/types";
import { MhcGeographyEnum } from "graphqlApi/types";

import { getReadableGeographyName } from "common/util/geographyHelpers";
import { sortGeographiesBySize } from "common/util/sortGeographiesBySize";

import { InvestigateDropdown } from "../charts/Investigate/InvestigateDropdown";
import { InvestigateMapProps } from "./InvestigateMap";

interface InvesitgateMapGranularitiesProps {
  displayDirection: InvestigateMapProps["displayDirection"];
  markerSectionTitle: InvestigateMapProps["markerSectionTitle"];
  markerSectionSubtitle: InvestigateMapProps["markerSectionSubtitle"];
  granularityTitle: InvestigateMapProps["granularityTitle"];
  defaultMapGranularity: InvestigateMapProps["defaultMapGranularity"];
  availableGeographies: InvestigateMapProps["availableGeographies"];
  investigations: InvestigateMapProps["investigations"];
  hideInvestigationDropdown: InvestigateMapProps["hideInvestigationDropdown"];
  selectedGranularity: string;
  setSelectedGranularity: (newGranularity: string) => void;
  setSelectedId: InvestigateMapProps["setSelectedId"];
}

export const InvesitgateMapGranularities = ({
  displayDirection,
  investigations,
  markerSectionTitle,
  markerSectionSubtitle,
  granularityTitle,
  defaultMapGranularity,
  availableGeographies,
  hideInvestigationDropdown,
  selectedGranularity,
  setSelectedGranularity,
  setSelectedId
}: InvesitgateMapGranularitiesProps) => {
  const mappedOptions: MappedDropdownOption[] = useMemo(() => {
    const geos = availableGeographies.map((geo) => geo.geography);
    return sortGeographiesBySize(geos).map((geography) => {
      return {
        title: getReadableGeographyName(geography),
        value: geography
      };
    });
  }, [availableGeographies]);

  useEffect(() => {
    setSelectedGranularity(
      mappedOptions[0]?.title ?? getReadableGeographyName(MhcGeographyEnum.ZipCode)
    );
  }, [mappedOptions, setSelectedGranularity, availableGeographies]);

  const handleMapTypeOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const title = (() => {
      const r = mappedOptions.filter((option) => option.value === event.target.value);
      if (r !== undefined && r.length > 0) {
        return r[0]?.title;
      }
      return undefined;
    })();
    setSelectedGranularity(title ?? "Zip Code");
    setSelectedId(undefined);
  };

  return (
    <Grid
      item
      xs={12}
      md={
        displayDirection === "column"
          ? 12
          : investigations.length === 0 || hideInvestigationDropdown
          ? 12
          : 5
      }
      sx={{
        height: "min-content",
        mt: markerSectionTitle || markerSectionSubtitle ? 0 : "auto",
        mb: displayDirection === "column" ? 3 : undefined,
        flex: "0 0 auto"
      }}
    >
      <InvestigateDropdown
        value={selectedGranularity}
        title={granularityTitle}
        onChange={handleMapTypeOnChange}
        defaultValue={defaultMapGranularity}
        options={Object.values(mappedOptions).map((option) => ({
          title: option.title,
          value: option.value
        }))}
        type="Map"
      />
    </Grid>
  );
};
