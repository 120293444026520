import { InvestigateMapProps } from "./types";

import { demographicColors } from "theme/colors";

import {
  basicPolygonFeatureStyle,
  locationPickerNoValueStyle,
  missingPolygonFeatureStyle,
  selectedPolygonFeatureFillStyle,
  selectedPolygonFeatureStyle
} from "common/components/GeoMap/mapStyles";

interface getInvestigateMapFeatureStyleProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  feature: any;
  selectedId?: string;
  ignoreFeatureValues: InvestigateMapProps["ignoreFeatureValues"];
}

export const getInvestigateMapFeatureStyle = ({
  feature,
  selectedId,
  ignoreFeatureValues
}: getInvestigateMapFeatureStyleProps) => {
  if (ignoreFeatureValues) {
    const _featureStyle =
      feature?.properties.id === selectedId
        ? {
            ...selectedPolygonFeatureStyle,
            ...selectedPolygonFeatureFillStyle
          }
        : locationPickerNoValueStyle;
    const featureStyle = { ..._featureStyle, color: demographicColors.race.asian };
    return featureStyle;
  }
  let base = {
    ...(feature?.properties.id === selectedId
      ? selectedPolygonFeatureStyle
      : basicPolygonFeatureStyle)
  };
  const { color } = feature.properties;
  if (color) {
    base["fillColor"] = color;
  } else {
    base = { ...base, ...missingPolygonFeatureStyle };
    if (feature?.properties.id === selectedId) {
      base = { ...base, ...selectedPolygonFeatureStyle };
    }
  }
  return base;
};
