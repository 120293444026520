import { InvestigateMapProps } from "./types";
import { MhcStatIdentifier } from "graphqlApi/types";

import { continuousColorScale, ScaleCallback } from "common/components/GeoMap/utils";
import { getGeographyEnumFromReadableName } from "common/util/geographyHelpers";

import { noDataMapColor, zeroMapColor } from "common/components/GeoMap/mapStyles";
import { InvestigateRange } from "../styles/investigateMapStyles";
import { getValueForFeatureId } from "./getValueForFeatureId";

export interface GetColorBasedOnValueProps {
  featureId?: string;
  customOptions: InvestigateMapProps["customOptions"];
  hideGeographyGranularitySelector: InvestigateMapProps["hideGeographyGranularitySelector"];
  investigations: InvestigateMapProps["investigations"];
  selectedCustomOption?: string;
  investigateType?: string;
  colorScale?: InvestigateMapProps["colorScale"];
  selectedSi?: MhcStatIdentifier;
  selectedRangeForLegend: InvestigateRange[];
  selectedGranularity: string;
  allValues?: Array<number | null>;
  clippedMaxValue?: number;
}

export const getColorBasedOnValue = ({
  featureId,
  customOptions,
  hideGeographyGranularitySelector,
  investigations,
  selectedCustomOption,
  investigateType,
  colorScale,
  selectedSi,
  selectedRangeForLegend,
  selectedGranularity,
  clippedMaxValue,
  allValues
}: GetColorBasedOnValueProps) => {
  const useContinuousScale = colorScale === "continuous";
  if (featureId === undefined) {
    return undefined;
  }
  const colorScaleFunctions: Record<string, ScaleCallback> = {};
  investigations.forEach(({ statId, minMax }) => {
    const geoEnum = getGeographyEnumFromReadableName(selectedGranularity);
    const investigationMinMax = minMax && geoEnum ? minMax[geoEnum] : undefined;
    const isPercentAndMaxIs100 = selectedSi?.isPercent && investigationMinMax?.maxValue === 100;
    if (!isPercentAndMaxIs100 && allValues?.length && selectedSi?.id) {
      const minMax: { minValue: number | null; maxValue: number | null } = {
        minValue: 0,
        maxValue: null
      };
      colorScaleFunctions[statId] = continuousColorScale([
        minMax?.minValue as number,
        clippedMaxValue ?? (minMax?.maxValue as number)
      ]);
      return;
    }
    if (
      !useContinuousScale ||
      investigationMinMax?.minValue === null ||
      investigationMinMax?.maxValue === null
    ) {
      colorScaleFunctions[statId] = () => noDataMapColor;
      return;
    }
    colorScaleFunctions[statId] = continuousColorScale([
      investigationMinMax?.minValue as number,
      clippedMaxValue ?? (investigationMinMax?.maxValue as number)
    ]);
  });

  const value = getValueForFeatureId({
    featureId,
    customOptions,
    hideGeographyGranularitySelector,
    investigations,
    selectedCustomOption,
    investigateType
  });
  if (value === undefined) {
    return undefined;
  }
  if (useContinuousScale) {
    const selectedStatId = selectedSi?.id;
    if (!selectedStatId) return undefined;
    const { minMax } = investigations.find(({ statId }) => statId === selectedStatId) || {};
    const geoEnum = getGeographyEnumFromReadableName(selectedGranularity);
    const investigationMinMax = minMax && geoEnum ? minMax[geoEnum] : undefined;
    if (investigationMinMax?.maxValue === null && investigationMinMax?.minValue === null) {
      return undefined;
    }
    const getColorMethod = colorScaleFunctions[selectedStatId];
    const { value: featureValue } = value;
    if (featureValue === 0) return zeroMapColor;
    if (!getColorMethod) return noDataMapColor;
    let color = getColorMethod(featureValue) as string;
    if (clippedMaxValue) {
      const clippedValue = featureValue > clippedMaxValue ? clippedMaxValue : featureValue;
      color = getColorMethod(clippedValue) as string;
    }
    return color;
  }
  const withinRange = selectedRangeForLegend.filter(
    (range) => range.condition(value.value) === true
  );
  if (withinRange.length > 0) {
    return withinRange[0]?.color ?? "black";
  }
  return undefined;
};
