import isNil from "lodash/isNil";

export type ValueRange = [number, number];
export const defaultRange: ValueRange = [0, 100];
export const DeltaLabelEnum = {
  SubstantialDecrease: "Substantial decrease",
  ModerateDecrease: "Moderate decrease",
  Stable: "Stable",
  ModerateIncrease: "Moderate increase",
  SubstantialIncrease: "Substantial increase",
  InsufficientData: "Insufficient data"
};
export const RangeLevelEnum = {
  Low: "Low",
  ModerateToLow: "Moderate to low",
  Moderate: "Moderate",
  High: "High",
  RelativelyHigh: "Relatively high",
  RelativelyModerate: "Relatively moderate",
  RelativelyLow: "Relatively low",
  VeryLow: "Very low",
  NoRating: "No rating",
  ...DeltaLabelEnum
};
type RangeLevelKeys = (typeof RangeLevelEnum)[keyof typeof RangeLevelEnum];
export type RangeConditions<T extends string = RangeLevelKeys> = Record<T, (v: number) => boolean>;
type LabelForDeltaOptions = { isPercent: boolean };
type Generator<Options extends object = LabelForDeltaOptions> = (
  value: number,
  conditions?: RangeConditions<RangeLevelKeys>,
  options?: Options
) => string | null;

export const rangeConditionsForScore: RangeConditions<RangeLevelKeys> = {
  Low: (v) => v <= 25,
  ModerateToLow: (v) => v > 25 && v <= 50,
  Moderate: (v) => v > 50 && v <= 75,
  High: (v) => v > 75
};

/**
 * Determines Score phrase for value
 *
 * @param value
 * @param conditions
 *
 * @returns phrase corresponding to matching condition
 *
 * @see rangeConditionsForScore
 *
 */
export const scoreForValue: Generator = (value, conditions = rangeConditionsForScore) => {
  switch (true) {
    case conditions.Low?.(value):
      return RangeLevelEnum.Low;
    case conditions.ModerateToLow?.(value):
      return RangeLevelEnum.ModerateToLow;
    case conditions.Moderate?.(value):
      return RangeLevelEnum.Moderate;
    case conditions.High?.(value):
      return RangeLevelEnum.High;
    default:
      return null;
  }
};

export const rangeConditionsForRank: RangeConditions<RangeLevelKeys> = {
  RelativelyHigh: (v) => v === 4,
  RelativelyModerate: (v) => v === 3,
  RelativelyLow: (v) => v === 2,
  VeryLow: (v) => v === 1,
  NoRating: (v) => v === -1,
  InsufficientData: (v) => v === -2
};

/**
 * Determines rank phrase for value
 *
 * @param value
 * @param conditions
 *
 * @returns phrase corresponding to matching condition
 *
 * @see rangeConditionsForRank
 *
 */
export const rankForValue: Generator = (value, conditions = rangeConditionsForRank) => {
  switch (true) {
    case conditions.RelativelyHigh?.(value):
      return RangeLevelEnum.RelativelyHigh;
    case conditions.RelativelyModerate?.(value):
      return RangeLevelEnum.RelativelyModerate;
    case conditions.RelativelyLow?.(value):
      return RangeLevelEnum.RelativelyLow;
    case conditions.VeryLow?.(value):
      return RangeLevelEnum.VeryLow;
    case conditions.NoRating?.(value):
      return RangeLevelEnum.NoRating;
    case conditions.InsufficientData?.(value):
      return RangeLevelEnum.InsufficientData;
    default:
      return null;
  }
};

export const rangeConditionsForDelta: RangeConditions = {
  SubstantialDecrease: (v) => v <= -20.0,
  ModerateDecrease: (v) => v >= -19.9 && v <= -10.0,
  Stable: (v) => v >= -9.9 && v <= 9.9,
  ModerateIncrease: (v) => v >= 10.0 && v <= 19.9,
  SubstantialIncrease: (v) => v >= 20.0,
  InsufficientData: (v) => isNil(v)
};

export const formattedRangeLevelValueRange = (isPercent = true) => {
  const percentSymbol = isPercent ? "%" : "";
  return {
    SubstantialDecrease: `<= -20.0${percentSymbol}`,
    ModerateDecrease: `-19.9${percentSymbol} to -10.0${percentSymbol}`,
    Stable: `-9.9${percentSymbol} to 9.9${percentSymbol}`,
    ModerateIncrease: `10.0${percentSymbol} to 19.9${percentSymbol}`,
    SubstantialIncrease: `>= 20.0${percentSymbol}`,
    InsufficientData: ""
  };
};

/**
 * Determines phrase for delta
 *
 * @param value
 * @param conditions
 * @param options
 * @param options.isPercent - Inform formatting
 * @returns phrase corresponding to matching condition
 *
 * @see rangeConditionsForDelta
 *
 */

export const labelForDelta: Generator = (
  value,
  conditions = rangeConditionsForDelta,
  options = { isPercent: true }
) => {
  const formattedRange = formattedRangeLevelValueRange(options.isPercent);
  switch (true) {
    case conditions.SubstantialDecrease?.(value):
      return `${RangeLevelEnum.SubstantialDecrease} (${formattedRange.SubstantialDecrease})`;
    case conditions.ModerateDecrease?.(value):
      return `${RangeLevelEnum.ModerateDecrease} (${formattedRange.ModerateDecrease})`;
    case conditions.Stable?.(value):
      return `${RangeLevelEnum.Stable} (${formattedRange.Stable})`;
    case conditions.ModerateIncrease?.(value):
      return `${RangeLevelEnum.ModerateIncrease} (${formattedRange.ModerateIncrease})`;
    case conditions.SubstantialIncrease?.(value):
      return `${RangeLevelEnum.SubstantialIncrease} (${formattedRange.SubstantialIncrease})`;
    case conditions.InsufficientData?.(value):
      return RangeLevelEnum.InsufficientData;
    default:
      return null;
  }
};

type GeneratorTypes = "score" | "rank" | "delta";
const rangePhraseGenerators: Record<GeneratorTypes, Generator> = {
  score: scoreForValue,
  rank: rankForValue,
  delta: labelForDelta
};

interface ScorePhraseArgs<T> {
  value: number | null;
  conditions?: RangeConditions<T & string>;
  type?: GeneratorTypes;
}

/**
 * Returns a string communicating the position of the value on a range based on conditions
 *
 * @param params
 * @param params.conditions
 * @param params.type
 * @param params.value
 *
 * @returns string from RangeLevelEnum - the value's position on a range in words
 *
 * @see RangeLevelEnum
 * @see ScorePhraseArgs
 *
 */
export const determineRangeValuePhrase = <T extends string = RangeLevelKeys>({
  conditions,
  type = "score",
  value
}: ScorePhraseArgs<T>) => {
  if (!value) return null;
  return rangePhraseGenerators[type]?.(value, conditions);
};

/**
 * Compares a given value through text to a given range of numbers
 *
 * @param value
 * @param range
 *
 * @returns human readable string comparing value to range
 */
export const determineRangeValueCaption = (
  value: number | string,
  range: ValueRange | undefined = defaultRange
) => {
  return `${value} on a scale of ${range.join("-")}`;
};
