import { InvestigateMapInvestigation } from "../InvestigateMap";

export const getBoundsOfInvestigationData = (data: InvestigateMapInvestigation["data"]) => {
  const values = Object.values(data)
    .map(({ value }) => value)
    .filter((x) => x !== null);
  const bounds = {
    minValue: values.length ? Math.min(...(values as number[])) : null,
    maxValue: values.length ? Math.max(...(values as number[])) : null
  };
  return bounds;
};
