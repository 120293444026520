"use client";

import React, { useCallback, useMemo } from "react";
import { Box, Stack, Typography, TypographyProps } from "@mui/material";
import isNil from "lodash/isNil";
import last from "lodash/last";

import { BivariatePoint, BivariateQuadrant } from "./util/types";

import { theme } from "theme/theme";
import { getRowColumnFromSelectedValue } from "./util/getRowColumnFromSelectedValue";
import { useIsMobile } from "common/util/hooks/useIsMobile";

import Row from "../Row";
import BivariateAxisLabel from "./BivariateAxisLabel";

export const BIVARIATE_LABEL_GUTTER_SPACE = 0.75;
export type MinMaxRange = { min: number | string; max: number | string };
export interface BivariateHeatmapProps {
  xAxisTitle: string;
  xAxisRange?: MinMaxRange;
  yAxisTitle: string;
  yAxisRange?: MinMaxRange;
  xAxisTitleUnit?: string;
  yAxisTitleUnit?: string;
  topRightTitle: string;
  bottomLeftTitle: string;
  selectedBivariatePoint?: BivariatePoint | undefined;
  xCategories: BivariateQuadrant[];
  yCategories: BivariateQuadrant[];
  yAxisMaxTitle?: string | null;
  yAxisMinTitle?: string | null;
  xAxisLabels?: (string | null)[];
  colors: string[][];
  showValueRangesInAxes?: boolean;
}

const Pog = () => (
  <Box
    sx={{
      backgroundColor: "brand.main",
      borderColor: "white",
      borderStyle: "solid",
      borderWidth: "5px",
      borderRadius: "100%",
      width: "35px",
      height: "35px",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 11
    }}
  />
);

export const NumberLabel: React.FC<{ content?: string | number; sx?: TypographyProps["sx"] }> = ({
  content,
  sx
}) => (
  <>
    {content && (
      <Typography variant="body3" fontWeight={700} lineHeight={1} sx={sx}>
        {content}
      </Typography>
    )}
  </>
);

const InnerLabel: React.FC<{ label: string; position: "upper" | "lower" }> = ({
  label,
  position
}) => {
  const isUpper = position === "upper";
  const isLower = position === "lower";
  const { spacing } = theme;
  const isMobile = useIsMobile();
  return (
    <Typography
      variant={isMobile ? "body2" : "body3"}
      textAlign={isLower ? "left" : "right"}
      component="span"
      sx={{
        p: 0.75,
        borderRadius: "4px",
        fontSize: 12,
        lineHeight: 1.1,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        gap: "2px",
        top: isUpper ? spacing(BIVARIATE_LABEL_GUTTER_SPACE) : undefined,
        right: isUpper ? spacing(BIVARIATE_LABEL_GUTTER_SPACE) : undefined,
        bottom: isLower ? spacing(BIVARIATE_LABEL_GUTTER_SPACE) : undefined,
        left: isLower ? spacing(BIVARIATE_LABEL_GUTTER_SPACE) : undefined,
        background: isLower ? "rgba(0,0,0,.5)" : "rgba(255,255,255,.5)",
        backdropFilter: "blur(10px)",
        zIndex: 10,
        maxWidth: "65%"
      }}
      color={isLower ? "common.white" : "common.black"}
    >
      {label}
    </Typography>
  );
};

const BivariateXAxisLabels: React.FC<{
  range?: MinMaxRange;
  labels: (string | null)[];
  showValueRangesInAxes: boolean;
  xCategories: BivariateHeatmapProps["xCategories"];
}> = ({ xCategories, labels, showValueRangesInAxes, range }) => {
  return (
    <>
      {labels.map((title, index) => {
        if (!title) return null;
        return (
          <Stack
            key={`${title ?? "_"}-${index}`}
            sx={{
              flex: 1,
              pt: BIVARIATE_LABEL_GUTTER_SPACE,
              alignItems:
                index === 0
                  ? "flex-start"
                  : xCategories.length - 1 === index
                  ? "flex-end"
                  : "center"
            }}
          >
            {showValueRangesInAxes && index === 0 && !isNil(range?.min) && (
              <NumberLabel content={range?.min} />
            )}
            {showValueRangesInAxes && labels.length - 1 === index && !isNil(range?.max) && (
              <NumberLabel content={range?.max} sx={{ textAlign: "right" }} />
            )}
            {title !== null && (
              <Typography
                variant="body3"
                display="block"
                textAlign={
                  index === 0 ? "left" : xCategories.length - 1 === index ? "right" : "center"
                }
                component="span"
                sx={{
                  mr: index === 0 ? "auto" : undefined,
                  ml: xCategories.length - 1 === index ? "auto" : undefined,
                  mb: "auto"
                }}
              >
                {title}
              </Typography>
            )}
          </Stack>
        );
      })}
    </>
  );
};

export const BivariateHeatmap: React.FC<BivariateHeatmapProps> = ({
  xAxisTitle,
  xAxisRange,
  yAxisTitle,
  yAxisRange,
  xAxisTitleUnit,
  yAxisTitleUnit,
  showValueRangesInAxes = false,
  topRightTitle,
  bottomLeftTitle,
  colors,
  selectedBivariatePoint,
  xCategories,
  yCategories,
  yAxisMaxTitle,
  yAxisMinTitle,
  xAxisLabels = []
}) => {
  const isMobile = useIsMobile();
  const selectedValues = useMemo((): { row: number; column: number } | undefined => {
    return getRowColumnFromSelectedValue(selectedBivariatePoint, xCategories, yCategories);
  }, [selectedBivariatePoint, xCategories, yCategories]);

  const getColor = useCallback(
    (row: number, column: number): string | undefined => {
      const loadedRow = colors[row];
      if (loadedRow === undefined) {
        return undefined;
      }
      return loadedRow[column] ?? undefined;
    },
    [colors]
  );

  const { spacing } = theme;

  return (
    <Stack flex={1} minHeight={{ xs: 350, lg: 450 }}>
      <Row width="100%" gap={1} flex={1}>
        <Stack alignItems="flex-end" justifyContent="space-between" width="20%">
          <BivariateAxisLabel title={yAxisMinTitle} value={yAxisRange?.min} />
          <Typography
            variant="body2"
            textAlign="right"
            component="span"
            sx={{
              display: "block",
              height: "min-content",
              justifyContent: "flex-end",
              lineHeight: 1.3
            }}
            color="light.primary"
          >
            {yAxisTitle.split(" ").map((word, i) => (
              <Typography
                variant={isMobile ? "body3" : "body2"}
                fontWeight={700}
                component="span"
                display="block"
                key={`title-word-${i}`}
                lineHeight={1.3}
              >
                {word}
              </Typography>
            ))}
            {yAxisTitleUnit && (
              <Typography
                variant="caption"
                textAlign="center"
                component="span"
                color="light.primary"
              >
                {yAxisTitleUnit}
              </Typography>
            )}
          </Typography>
          <BivariateAxisLabel title={yAxisMaxTitle} value={yAxisRange?.max} />
        </Stack>
        <Stack flex={1} gap="1px" position="relative">
          <InnerLabel label={topRightTitle} position="upper" />
          <InnerLabel label={bottomLeftTitle} position="lower" />
          {yCategories.map((_category, rowIndex) => {
            return (
              <Row key={rowIndex} gap="1px" flex={1} position="relative">
                {xCategories.map((value, colIndex) => {
                  return (
                    <Row
                      key={`${rowIndex}-${colIndex}`}
                      sx={{
                        flex: 1,
                        position: "relative",
                        backgroundColor: getColor(rowIndex, colIndex)
                      }}
                    >
                      {colIndex === selectedValues?.column && rowIndex === selectedValues?.row && (
                        <Pog />
                      )}
                    </Row>
                  );
                })}
              </Row>
            );
          })}
        </Stack>
      </Row>
      <Stack flex={0} sx={{ pl: `calc(20% + ${spacing(1)})` }} gap={1}>
        <Row flex={1}>
          {xAxisLabels.length === 2 && (
            <>
              <BivariateAxisLabel title={xAxisLabels[0]} value={xAxisRange?.min} />
              <BivariateAxisLabel title={last(xAxisLabels)} value={xAxisRange?.max} />
            </>
          )}
          {xAxisLabels.length > 2 && (
            <BivariateXAxisLabels
              labels={xAxisLabels}
              xCategories={xCategories}
              range={xAxisRange}
              showValueRangesInAxes={showValueRangesInAxes}
            />
          )}
        </Row>
        <Stack>
          <Typography
            variant={isMobile ? "body3" : "body2"}
            textAlign="center"
            component="span"
            color="light.primary"
            fontWeight={700}
          >
            {xAxisTitle}
          </Typography>
          {xAxisTitleUnit && (
            <Typography variant="caption" textAlign="center" component="span" color="light.primary">
              {xAxisTitleUnit}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
