import { ReactElement } from "react";
import { Box, BoxProps, Divider, Skeleton, Typography } from "@mui/material";

import { DEFAULT_SKELETON_ANIMATION } from "layout/configuration";

import Row from "common/components/Row";

export interface ContentCardSectionTitleSkeleton {
  active?: boolean;
  minWidth?: number;
}

const CardSectionTitleChoices = ["inline", "under_divider"] as const;
export type CardSectionTitleType = (typeof CardSectionTitleChoices)[number];

interface Props {
  title: React.ReactNode;
  subtitle?: string;
  skeleton?: ContentCardSectionTitleSkeleton;
  variant?: CardSectionTitleType;
  utilityLink?: ReactElement | null;
  additionalContext?: ReactElement;
  dates?: ReactElement;
  sx?: BoxProps["sx"];
}

export const ContentCardSectionTitle: React.FC<Props> = ({
  title,
  subtitle,
  utilityLink = null,
  additionalContext = null,
  dates = null,
  skeleton,
  variant = "inline",
  sx = {}
}) => {
  return (
    <Box sx={{ marginBottom: 4, ...sx }}>
      {variant === "inline" && (
        <Row sx={{ alignItems: "center", gap: { xs: 0.5, md: 1 } }}>
          <Typography
            variant="h3"
            component="h3"
            sx={{ flex: "0 1 auto", maxWidth: { xs: "65%", md: "none" } }}
          >
            {skeleton?.active !== true ? (
              title
            ) : (
              <Skeleton
                animation={DEFAULT_SKELETON_ANIMATION}
                sx={{
                  minWidth: `${skeleton?.minWidth !== undefined ? skeleton?.minWidth : 100}px`
                }}
              />
            )}
          </Typography>
          <Divider sx={{ flex: "1", mt: "5px" }} />
          {(additionalContext || dates || utilityLink) && (
            <Box sx={{ ml: { xs: 0, md: 4 }, textAlign: { xs: "left", md: "right" } }}>
              {additionalContext}
              {dates}
              {utilityLink && <Box sx={{ flex: "0 1 auto" }}>{utilityLink}</Box>}
            </Box>
          )}
        </Row>
      )}
      {variant === "under_divider" && (
        <>
          <Divider sx={{ flex: "1 1 100%", mb: 4 }} />
          <Typography variant="h4" component="h4" sx={{ mb: 2 }}>
            {skeleton?.active !== true ? (
              title
            ) : (
              <Skeleton
                animation={DEFAULT_SKELETON_ANIMATION}
                sx={{
                  minWidth: `${skeleton?.minWidth !== undefined ? skeleton?.minWidth : 100}px`,
                  maxWidth: "50%"
                }}
              />
            )}
          </Typography>
        </>
      )}
      {subtitle && (
        <Typography variant="h4" component="p" mt={0.5}>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};
