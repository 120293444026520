"use client";

import { create } from "zustand";

import { MhcTopicFragment } from "graphqlApi/types";

import { NoCountry } from "common/components/LocationSwitcher/util/groupAndTurnLocationsIntoFeatures";

type State = {
  selectedId?: string;
  shouldShowOnlyLocationsWithData: boolean;
  locationSwitcherSelectedGeography?: NoCountry;
  selectedTopic?: MhcTopicFragment;
};

type Actions = {
  updateState: (newValues: Partial<State>) => void;
};

export const useLocationSwitcherStore = create<State & Actions>((set) => ({
  selectedId: undefined,
  selectedTopic: undefined,
  shouldShowOnlyLocationsWithData: false,
  locationSwitcherSelectedGeography: undefined,
  updateState: (newValues: Partial<State>) => set((state) => ({ ...state, ...newValues }))
}));
