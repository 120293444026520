import { FetchKpiOptions } from "../../util/fetchingFunctions/kpi/types";
import { TopicDictionary } from "app/topics/util/types";

export const kpiOptionsByTopic: TopicDictionary<FetchKpiOptions> = {
  copd: { granularity: "year", forceRelatedStatTitle: true }
};

export const kpiTitleOverrides: Record<string, string> = {
  COPD_CDC_PLACES_CRUDE_RATE: "COPD Prevalence"
};
