"use client";

import { useCallback, useMemo, useRef, useState } from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import compact from "lodash/compact";
import { getLocationWithGeoJson, getMhcDateSeries } from "graphqlApi/legacy/mhcClient";

import { IndicatorColumnIds } from "../types";
import { MhcGeographyEnum, MhcLocationFragment, MhcTimeSeries } from "graphqlApi/types";

import { columnIdsOptions } from "../constants";
import { IconIdEnum } from "common/components/Icons/utils";
import { logError } from "common/util/consoleHelpers";
import { sendGaUserInteractionEvent } from "common/util/googleAnalytics";

import { ContentCardSubSection } from "layout/card/ContentCardSubSection";
import { DataNotAvailableNote } from "common/components/Alerts/DataNotAvailableNote";
import { MhcAlert } from "common/components/Alerts/MhcAlert";
import { PdfSupressionContext } from "common/components/Pdf/PdfSupressionContext";
import Row from "common/components/Row";
import { IndicatorTable, IndicatorTableProps, IndicatorTableRow } from "../IndicatorTable";
import { IndicatorTableKey } from "../IndicatorTableKey";
import { IndicatorModal } from "./IndicatorModal";

export interface IndicatorTableContentWrapperProps extends Pick<BoxProps, "sx"> {
  columnsToShow?: IndicatorColumnIds[];
  columnWidths?: Record<IndicatorColumnIds, string>;
  columnTitleOverrides?: IndicatorTableProps["columnTitleOverrides"];
  currentTopicSlug?: string;
  introText?: string;
  isMemberOfGroup?: boolean;
  loadStateData?: boolean;
  location: MhcLocationFragment;
  renderGhostColumns?: boolean;
  renderPdfSuppression?: boolean;
  rows: IndicatorTableRow[];
  showImprovement?: boolean;
  showTargets?: boolean;
  showTrends?: boolean;
  subContent?: React.ReactElement;
  tableId?: string;
  tableSorting?: IndicatorTableProps["sorting"];
  title?: string;
  uiLocation?: string;
  useCounty?: boolean;
  useRatioForValues?: boolean;
}

export const IndicatorTableContentWrapper = ({
  columnsToShow = columnIdsOptions,
  columnWidths,
  columnTitleOverrides,
  currentTopicSlug,
  introText = "",
  isMemberOfGroup = false,
  loadStateData = true,
  location,
  renderGhostColumns,
  renderPdfSuppression = true,
  rows = [],
  showImprovement = true,
  showTargets = true,
  showTrends = true,
  subContent,
  sx = {},
  tableId,
  tableSorting,
  title,
  uiLocation,
  useCounty,
  useRatioForValues,
  ...props
}: IndicatorTableContentWrapperProps) => {
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const [selectedRow, setSelectedRow] = useState<IndicatorTableRow | undefined>(undefined);
  const [stateLocation, setStateLocation] = useState<MhcLocationFragment | undefined>(undefined);
  const [stateTimeSeries, setStateTimeSeries] = useState<MhcTimeSeries | undefined>(undefined);
  const [locationTimeSeries, setLocationTimeSeries] = useState<MhcTimeSeries | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const container = useRef<HTMLDivElement>(null);

  const isOpen = useMemo(() => {
    return selectedRowId !== null;
  }, [selectedRowId]);

  const handleClose = () => {
    setSelectedRowId(null);
    setSelectedRow(undefined);
    setStateLocation(undefined);
    setStateTimeSeries(undefined);
  };

  const getLocationStats = useCallback(
    async (statId: string, locationId: string) => {
      setIsLoading(true);
      try {
        if (!loadStateData) {
          setIsLoading(false);
          return;
        } else {
          let _stateLocation = null;
          if (stateLocation === undefined && locationId !== "state") {
            _stateLocation = (await getLocationWithGeoJson({
              locationId: "state"
            })) as MhcLocationFragment;
          }
          const { timeSeries: stateTs } = await getMhcDateSeries({
            locationId: "state",
            statId,
            granularity: selectedRow?.granularity
          });
          if (_stateLocation && stateTs !== undefined) {
            setStateLocation(_stateLocation);
            setStateTimeSeries(stateTs as MhcTimeSeries);
          }
        }
        const { timeSeries } = await getMhcDateSeries({
          locationId,
          statId,
          granularity: selectedRow?.granularity
        });
        if (timeSeries !== undefined) {
          setLocationTimeSeries(timeSeries as MhcTimeSeries);
        }
      } catch {
        logError(
          `Problem fetching location stat for Indicator data table info modal; ID: ${statId}; Location: state`
        );
      } finally {
        setIsLoading(false);
      }
    },
    [stateLocation, selectedRow?.granularity, loadStateData]
  );

  const handleRowClick = useCallback(
    (id: string, row: IndicatorTableRow) => {
      const selectedRowId = id;
      if (selectedRowId === null) {
        return;
      }
      setSelectedRow(row);
      if (row !== undefined) {
        void getLocationStats(row.si.id, location.id);
      }
      sendGaUserInteractionEvent({
        category: "Tables",
        action: "Row Click",
        label: `${(row?.si?.name as string) ?? ""} (${(row?.si?.id as string) ?? ""})`,
        ui_location: uiLocation
      });
      setSelectedRowId(id);
    },
    [getLocationStats, location.id, uiLocation]
  );

  const legendIconsToShow = compact([
    showTargets ? IconIdEnum.NeedsAttention : null,
    showImprovement ? IconIdEnum.Improving : null
  ]);

  const GroupTableTitle = () => (
    <Typography variant="h4" component="h4" mb={2}>
      {title}
    </Typography>
  );

  if (rows.length === 0) {
    return (
      <>
        <GroupTableTitle />
        <DataNotAvailableNote />
      </>
    );
  }

  return (
    <Box ref={container}>
      <ContentCardSubSection
        title={isMemberOfGroup ? undefined : title}
        component="div"
        sx={{
          ...sx
        }}
      >
        {isMemberOfGroup && <GroupTableTitle />}
        {introText && (
          <Typography mb={4} sx={{ maxWidth: "90ch" }}>
            {introText}
          </Typography>
        )}
        {subContent}
        <Row sx={{ mb: 2, flexWrap: "wrap", gap: 2 }}>
          <Box className="web-only-no-pdf" sx={{ mr: "auto", flex: "1 1 auto" }}>
            <MhcAlert severity="info" sx={{ width: { xs: "100%", md: "auto" } }}>
              <Typography variant="body2" fontWeight={500}>
                To investigate the data, click a table row
              </Typography>
            </MhcAlert>
          </Box>
          {legendIconsToShow.length && <IndicatorTableKey iconsToShow={legendIconsToShow} />}
        </Row>
        <IndicatorTable
          id={tableId}
          columnWidths={columnWidths}
          columnsToShow={columnsToShow}
          columnTitleOverrides={columnTitleOverrides}
          location={location}
          onRowClick={handleRowClick}
          rows={rows}
          showTargets={showTargets}
          showTrends={showTrends}
          title={title}
          useCounty={useCounty}
          useRatioForValues={useRatioForValues}
          renderGhostColumns={renderGhostColumns}
          sorting={tableSorting}
          {...props}
        />
        {renderPdfSuppression && <PdfSupressionContext />}
      </ContentCardSubSection>
      <IndicatorModal
        open={isOpen}
        handleClose={handleClose}
        selectedRow={selectedRow}
        isLoading={isLoading}
        stateTimeSeries={stateTimeSeries}
        stateLocation={stateLocation}
        locationTimeSeries={locationTimeSeries}
        location={
          useCounty
            ? {
                name: location.county?.name ?? "",
                id: location.county?.id ?? "",
                __typename: "MhcLocation",
                geography: MhcGeographyEnum.County
              }
            : location
        }
        currentTopicSlug={currentTopicSlug}
        container={container.current}
      />
    </Box>
  );
};
