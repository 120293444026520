import { Skeleton, Stack } from "@mui/material";

import { DEFAULT_SKELETON_STYLE } from "layout/configuration";

const ContentCardHeaderFallback = () => (
  <Stack gap={1}>
    <Skeleton
      height={40}
      sx={{ minWidth: 300, width: "25%", ...DEFAULT_SKELETON_STYLE }}
      variant="rectangular"
    />
    <Skeleton
      height={20}
      sx={{ minWidth: 350, width: "45%", ...DEFAULT_SKELETON_STYLE }}
      variant="rectangular"
    />
  </Stack>
);

export default ContentCardHeaderFallback;
