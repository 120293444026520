import { Grid, Typography } from "@mui/material";

import { InvestigateMapProps } from "./InvestigateMap";

interface InvestigateMapDescriptionProps {
  displayDirection: InvestigateMapProps["displayDirection"];
  dataToInvestigateDescription: InvestigateMapProps["dataToInvestigateDescription"];
}

export const InvestigateMapDescription = ({
  displayDirection,
  dataToInvestigateDescription
}: InvestigateMapDescriptionProps) => {
  return (
    <Grid
      item
      xs={12}
      md={displayDirection === "column" ? 12 : 6}
      sx={{
        ml: displayDirection === "row" ? "auto" : undefined,
        mt: "15px",
        height: "min-content",
        width: "100%",
        bgColor: "red"
      }}
    >
      <Typography
        sx={{ whiteSpace: "pre-line", wordWrap: "break-word", lineHeight: "25px" }}
        variant="body2"
      >
        {dataToInvestigateDescription}
      </Typography>
    </Grid>
  );
};
