import PopoverContent from "../../PopoverContent";
import { ImprovingIcon } from "./Improving";

const ImprovingPopoverContent = () => (
  <PopoverContent
    title="Improving"
    icon={<ImprovingIcon tooltipped={false} fontSize="medium" />}
    textContent='An indicator is marked with the "Improving" icon if the value for that indicator has changed in a way that indicates improved health conditions. For example, if the rate of diabetes decreased during the period of change shown, the icon would appear, because less diabetes is a sign of better health.'
  />
);

export default ImprovingPopoverContent;
