import { Domain } from "common/components/GeoMap/utils";
import { logError } from "common/util/consoleHelpers";

export const calculateAverage = (array: number[]): number | null => {
  if (!array?.length) {
    logError("Error in calculating average — no values provided");
    return null;
  }
  return array.reduce((a, b) => a + b) / array.length;
};

// https://stackoverflow.com/questions/7343890/standard-deviation-javascript
export const getStandardDeviation = (array: number[]): number | null => {
  const n = array?.length;
  if (!n) {
    logError("Error in calculating standard deviation — no values provided");
    return null;
  }
  const mean = array.reduce((a, b) => a + b) / n;
  return Math.sqrt(array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n);
};

export const calculateClippedRange = (allValues: Array<number | null>): Domain | null => {
  const CLIPPING_MULTIPLIER = 1.96;

  if (!allValues.length) {
    logError(
      "Error in determining range of values for continuous color scale — no values provided"
    );
    return null;
  }
  // Filter out null, undefined, and negative values
  const normalizedValues = allValues.filter(
    (value) => value !== null && value !== undefined && value >= 0
  ) as number[];
  const average = calculateAverage(normalizedValues);
  const standardDeviation = getStandardDeviation(normalizedValues);
  if (!average || !standardDeviation) return null;
  return [0, standardDeviation * CLIPPING_MULTIPLIER + average];
};
