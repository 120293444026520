import { MhcFeatureCollection } from "graphqlApi/types";

export const bringGeoJsonFeatureToTheTop = (
  selectedId: string,
  geoJson: MhcFeatureCollection | undefined
): undefined | MhcFeatureCollection => {
  if (geoJson === undefined) {
    return undefined;
  }
  const features = geoJson.features;
  if (features === undefined) {
    return geoJson;
  }
  const filteredFeature = features.filter((feature) => feature.properties.id === selectedId);
  if (filteredFeature.length === 0) {
    return geoJson;
  }
  const selectedFeature = filteredFeature[0];
  if (selectedFeature === undefined) {
    return geoJson;
  }
  const nonSelectedFeatures = features.filter((feature) => feature.properties.id !== selectedId);
  return { ...geoJson, features: [...nonSelectedFeatures, selectedFeature] };
};
