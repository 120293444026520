import { InvestigateMapProps } from "./types";

interface getInvestigationForIdentifierProps {
  selectedStatId?: string;
  investigations: InvestigateMapProps["investigations"];
}

export const getInvestigationForIdentifier = ({
  selectedStatId,
  investigations
}: getInvestigationForIdentifierProps) => {
  if (!selectedStatId) return null;
  return investigations.find(({ statId }) => statId === selectedStatId) || null;
};
