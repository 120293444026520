import { Box, SvgIconProps, Typography } from "@mui/material";

import { ICON_IDS, IconIdEnum } from "../Icons/utils";

import { ImprovingIcon, NeedsAttentionIcon } from "../Icons";

const KeyBox: React.FC<{ icon: React.ReactNode; title: string }> = ({ icon, title }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      ml={1.5}
      px={1.5}
      py={1}
      sx={{
        borderColor: "rgba(0,0,0,0.2)",
        borderStyle: "solid",
        borderWidth: "0.24px",
        borderRadius: "3.87px"
      }}
    >
      <Box display="flex" alignItems="center" className="svg-icon-wrapper">
        {icon}
      </Box>
      <Typography variant="body3" sx={{ ml: 1 }}>
        {title}
      </Typography>
    </Box>
  );
};

const supportedIcons = ICON_IDS;
type SupportedIcons = (typeof supportedIcons)[number];

interface Props {
  title: string;
  iconsToShow?: SupportedIcons[];
  alignRight?: boolean;
}

export const KPIIconKey: React.FC<Props> = ({
  title,
  iconsToShow = supportedIcons,
  alignRight = true
}) => {
  const iconSX: Omit<SvgIconProps, "color"> = {
    width: "25px",
    height: "25px",
    fontSize: "small",
    sx: {
      my: "auto"
    }
  };
  return (
    <Box display="flex" alignItems="center" ml={alignRight ? "auto" : undefined}>
      <Typography variant="body3" fontWeight={400} sx={{ py: 1 }}>
        {title} Key
      </Typography>
      {iconsToShow.includes(IconIdEnum.NeedsAttention) && (
        <KeyBox icon={<NeedsAttentionIcon {...iconSX} />} title="Needs Attention" />
      )}
      {iconsToShow.includes(IconIdEnum.Improving) && (
        <KeyBox icon={<ImprovingIcon {...iconSX} />} title="Improving" />
      )}
    </Box>
  );
};
