"use client";

import { useState } from "react";

import { MhcStatIdentifier } from "graphqlApi/types";

import { InvestigateMap, InvestigateMapProps } from "./InvestigateMap";

export const InvestigateMapBaseWrapper = ({
  investigations: initialInvestigations,
  ...props
}: Omit<
  InvestigateMapProps,
  | "mapGranularity"
  | "updateMapGranularity"
  | "selectedId"
  | "setSelectedId"
  | "mapSelectedFeature"
  | "setMapSelectedFeature"
  | "selectedSi"
  | "setSelectedSi"
  | "setInvestigations"
>) => {
  const [mapGranularity, updateMapGranularity] = useState<string>("Zip Code");
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);
  const [mapSelectedFeature, setMapSelectedFeature] = useState<string | undefined>(undefined);
  const [selectedSi, setSelectedSi] = useState<MhcStatIdentifier | undefined>(undefined);
  const [investigations, setInvestigations] =
    useState<InvestigateMapProps["investigations"]>(initialInvestigations);

  return (
    <InvestigateMap
      {...props}
      mapGranularity={mapGranularity}
      updateMapGranularity={updateMapGranularity}
      selectedId={selectedId}
      setSelectedId={setSelectedId}
      mapSelectedFeature={mapSelectedFeature}
      setMapSelectedFeature={setMapSelectedFeature}
      selectedSi={selectedSi}
      setSelectedSi={setSelectedSi}
      investigations={investigations}
      setInvestigations={setInvestigations}
    />
  );
};
