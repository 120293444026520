import { BivariatePoint, BivariateQuadrant } from "./types";

export const getRowColumnFromSelectedValue = (
  selectedBivariatePoint: BivariatePoint | undefined,
  xCategories: BivariateQuadrant[],
  yCategories: BivariateQuadrant[]
): { row: number; column: number } | undefined => {
  if (selectedBivariatePoint === undefined) {
    return undefined;
  }
  const { xAxis: selectedValueX, yAxis: selectedValueY } = selectedBivariatePoint;
  if (selectedValueX === undefined || selectedValueY === undefined) {
    return undefined;
  }
  const xIndex = xCategories.findIndex((quadrant) => {
    if (selectedValueX >= quadrant.min) {
      if (quadrant.max !== undefined) {
        return selectedValueX <= quadrant.max ?? false;
      } else {
        return true;
      }
    }
    return false;
  });
  const yIndex = yCategories.findIndex((quadrant) => {
    if (selectedValueY >= quadrant.min) {
      if (quadrant.max !== undefined) {
        return selectedValueY <= quadrant.max ?? false;
      } else {
        return true;
      }
    }
    return false;
  });
  return {
    row: yIndex,
    column: xIndex
  };
};
