import PopoverContent from "../../PopoverContent";
import { NeedsAttentionIcon } from ".";

const NeedsAttentionPopoverContent = () => (
  <PopoverContent
    title="Needs Attention"
    icon={<NeedsAttentionIcon tooltipped={false} fontSize="medium" />}
    textContent='
    An indicator is marked with the "Needs Attention" icon if the value for that
    indicator is worse than the target value. If no target value has been set, the icon will not
    appear.
  '
  />
);

export default NeedsAttentionPopoverContent;
