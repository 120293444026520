import { useMemo } from "react";
import { Link, Typography } from "@mui/material";

import { AdditionalInfoProps } from "modules/Topics/content/additionalInfo/items/types";

import AdditionalInfoPrivacyContent from "modules/Topics/content/additionalInfo/PrivacyContent";

import ResourcesAndAttributions from "modules/Topics/components/TopicAdditionalInfo/ResourcesAndAttributions";

const AsthmaAdditionalResources: React.FC<AdditionalInfoProps> = ({
  attributions = [],
  resources = []
}) => {
  const sortedResources = useMemo(() => {
    return [...resources].sort((a, b) => {
      if (a?.title < b?.title) return -1;
      if (a?.title > b?.title) return 1;
      return 0;
    });
  }, [resources]);

  const additionalAttributionsContent = (
    <>
      <Typography variant="body1" fontWeight={400} sx={{ mt: 4 }}>
        Citation:
      </Typography>
      <Typography variant="body1" fontWeight={600}>
        Special Report: Asthma in Delaware
      </Typography>
      <Typography variant="body1" fontWeight={400}>
        Delaware, T. S. of. (n.d.).{" "}
        <i>
          <Link href="https://dhss.delaware.gov/dph/dpc/asthma.html#:~:text=About%2046%2C000%20adult%20Delawareans%20are,and%20%2430%20million%20a%20year">
            Report details asthma prevalence and burden in Delaware.
          </Link>
        </i>{" "}
        DPH: Burden of Asthma in Delaware - Delaware Health and Social Services - State of Delaware.
      </Typography>
    </>
  );

  return (
    <>
      <ResourcesAndAttributions
        resources={sortedResources}
        attributions={attributions}
        additionalAttributionsContent={additionalAttributionsContent}
      />
      <AdditionalInfoPrivacyContent />
    </>
  );
};

export default AsthmaAdditionalResources;
