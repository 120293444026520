"use client";

import React, { ReactNode } from "react";
import { AppBar, Box } from "@mui/material";

import { DEFAULT_MARGIN_STR, SITE_HEADER_HEIGHT } from "layout/configuration";
import { theme } from "theme/theme";
import { useIsMobile } from "common/util/hooks/useIsMobile";
import { cssMediaQuery } from "common/util/style";

import MenuButton from "component/button/MenuButton";

interface Props {
  isOpen: boolean;
  handleOpenMenu?: () => void;
  children?: ReactNode;
}

const ContextHeader: React.FC<Props> = ({ children, isOpen, handleOpenMenu }) => {
  const isMobile = useIsMobile();
  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        alignItems: "stretch",
        justifyContent: "center",
        top: SITE_HEADER_HEIGHT,
        height: SITE_HEADER_HEIGHT,
        backgroundColor: "white",
        borderBottomWidth: "1px",
        borderBottomColor: theme.palette.borders.light,
        borderBottomStyle: "solid",
        py: 0,
        px: { xs: 1.5, lg: DEFAULT_MARGIN_STR },
        [cssMediaQuery({ maxOrMin: "max" })]: {
          display: "none"
        }
      }}
    >
      <Box display="flex" alignItems="stretch" width="100%" flex={1}>
        {!isOpen && !isMobile && handleOpenMenu && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mr: { sm: 3, lg: 4 },
              pr: { sm: 2, lg: 3 },
              borderRightColor: isMobile ? "transparent" : theme.palette.borders.light,
              borderRightWidth: 1,
              borderRightStyle: "solid"
            }}
          >
            <MenuButton handleClick={handleOpenMenu} />
          </Box>
        )}
        {children}
      </Box>
    </AppBar>
  );
};

export default ContextHeader;
