import { Box, Link as MuiLink } from "@mui/material";

import { sendGaNavigationEvent } from "common/util/googleAnalytics";

import { MhcLogo } from "common/components/MhcLogo";

export const SiteTopNavMHCLogo = () => {
  return (
    <MuiLink
      href="/"
      sx={{ display: "flex", alignItems: "center" }}
      onClick={() => sendGaNavigationEvent({ category: "Headers", action: "Logo Click" })}
    >
      <Box
        sx={({ breakpoints }) => ({
          display: "flex",
          alignItems: "center",
          flexShrink: "0",
          marginRight: "16px",
          width: {
            xs: "250px",
            md: "350px"
          },
          "& .mhc-logo__sub-title": {
            [breakpoints.down("md")]: {
              width: "350px",
              display: "block",
              fill: "#000"
            }
          }
        })}
      >
        <MhcLogo />
      </Box>
    </MuiLink>
  );
};
