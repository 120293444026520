"use client";

import "leaflet/dist/leaflet.css";

import { ReactNode, useMemo } from "react";
import dynamic from "next/dynamic";
import { Box } from "@mui/material";

import { BaseMapProps } from "common/components/GeoMap/BaseMap";

export type GeoMapProps = BaseMapProps;

// dynamic import to work around leaflet not working with SSG
export const GeoMap: React.FC<BaseMapProps & { fallback?: ReactNode }> = ({
  width = "100%",
  height = "100%",
  ...props
}) => {
  const Component = useMemo(
    () =>
      dynamic(async () => import("common/components/GeoMap/BaseMap"), {
        ssr: false, // This line is important. It's what prevents server-side render,
        loading: () => <Box sx={{ height, backgroundColor: "#d4dadc" }} />
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.externallySelectedFeatureId, props.geoJSON]
  );
  return <Component width={width} height={height} {...props} />;
};
