import { Box, BoxProps, Typography } from "@mui/material";

interface Props {
  sx?: BoxProps["sx"];
  overrideMessage?: string;
}

export const TopicDataNotAvailableNote: React.FC<Props> = ({ sx, overrideMessage }) => (
  <Box
    sx={{
      backgroundColor: "component.background",
      p: 3,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      ...sx
    }}
  >
    <Typography>
      {overrideMessage ||
        "Due to insufficient data, community-specific data is unable to be shown in this section."}
    </Typography>
  </Box>
);
