import { BivariateHeatmapColors } from "./types";

export const HIGH_SVI_COLOR = "#106E7C";
export const MODERATE_SVI_COLOR = "#4BC2D2";
export const MODERATE_TO_LOW_SVI_COLOR = "#9BD7E0";
export const LOW_SVI_COLOR = "#CAE6EA";

export const defaultSviColors: BivariateHeatmapColors = {
  high: HIGH_SVI_COLOR,
  moderate: MODERATE_SVI_COLOR,
  moderateToLow: MODERATE_TO_LOW_SVI_COLOR,
  low: LOW_SVI_COLOR
};

export const BIVARIATE_HIGH_COLOR = "#0E4369";
export const BIVARIATE_MODERATE_COLOR = "#3B7096";
export const BIVARIATE_MODERATE_TO_LOW_COLOR = "#75AAD0";
export const BIVARIATE_LOW_COLOR = "#BFE4FF";

export const DEFAULT_BIVARIATE_COLOR_MAP: BivariateHeatmapColors = {
  high: BIVARIATE_HIGH_COLOR,
  moderate: BIVARIATE_MODERATE_COLOR,
  moderateToLow: BIVARIATE_MODERATE_TO_LOW_COLOR,
  low: BIVARIATE_LOW_COLOR
};

export const DEFAULT_BIVARIATE_HEATMAP_COLORS: string[][] = [
  [
    BIVARIATE_MODERATE_COLOR,
    BIVARIATE_MODERATE_TO_LOW_COLOR,
    BIVARIATE_LOW_COLOR,
    BIVARIATE_LOW_COLOR
  ],
  [
    BIVARIATE_MODERATE_COLOR,
    BIVARIATE_MODERATE_TO_LOW_COLOR,
    BIVARIATE_MODERATE_TO_LOW_COLOR,
    BIVARIATE_LOW_COLOR
  ],
  [
    BIVARIATE_HIGH_COLOR,
    BIVARIATE_MODERATE_COLOR,
    BIVARIATE_MODERATE_TO_LOW_COLOR,
    BIVARIATE_LOW_COLOR
  ],
  [BIVARIATE_HIGH_COLOR, BIVARIATE_HIGH_COLOR, BIVARIATE_MODERATE_COLOR, BIVARIATE_LOW_COLOR]
];
