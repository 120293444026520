import React, { ReactNode } from "react";
import { Grid } from "@mui/material";

import { StatAttributions } from "../../util/elementHelpers/dashboard/types";
import { MhcResource } from "graphqlApi/types";

import { DEFAULT_COLUMN_SPACING } from "layout/configuration";

import ContentCardLinkList from "layout/card/ContentCardLinkList";
import { ContentCardSectionTitle } from "layout/card/ContentCardSectionTitle";
import { ContentCardSubSection } from "layout/card/ContentCardSubSection";
import { TopicAttributionList } from "../TopicAttributionList";

type ResourcesAndAttributionsProps = {
  resources?: MhcResource[];
  additionalResourcesContent?: ReactNode;
  attributions?: StatAttributions;
  additionalAttributionsContent?: ReactNode;
};

const ResourcesAndAttributions: React.FC<ResourcesAndAttributionsProps> = ({
  resources,
  additionalResourcesContent,
  attributions,
  additionalAttributionsContent
}) => {
  const hasResources = resources && resources.length > 0;
  const hasAttributions = attributions && attributions.length > 0;
  if (!hasResources && !hasAttributions) return null;
  return (
    <Grid container spacing={DEFAULT_COLUMN_SPACING}>
      {hasResources && (
        <Grid item xs={12} lg={hasAttributions ? 6 : 12}>
          <ContentCardSectionTitle title="Resources" />
          <ContentCardLinkList links={resources} />
          {additionalResourcesContent}
        </Grid>
      )}

      {hasAttributions && (
        <Grid item xs={12} lg={hasResources ? 6 : 12}>
          <ContentCardSubSection title="Data Sources" sx={{ mb: 0 }}>
            <TopicAttributionList attributions={attributions} />
            {additionalAttributionsContent}
          </ContentCardSubSection>
        </Grid>
      )}
    </Grid>
  );
};

export default ResourcesAndAttributions;
