import { ApolloClient, ApolloError, MutationOptions, QueryOptions } from "@apollo/client";
import { DocumentNode } from "graphql";

import { getSdk, Requester } from "../types";

import { log, logError } from "common/util/consoleHelpers";

export type ApolloRequesterOptions<V, R> =
  | Omit<QueryOptions<V>, "variables" | "query">
  | Omit<MutationOptions<R, V>, "variables" | "mutation">;

const validDocDefOps = ["mutation", "query", "subscription"];

// https://gist.github.com/akozhemiakin/731b0c1e99eb89b01f80f08f9146b6b6
export function getSdkApollo<C>(client: ApolloClient<C>) {
  const requester: Requester = async <R, V>(
    doc: DocumentNode,
    variables: V,
    options?: ApolloRequesterOptions<V, R>
  ): Promise<R> => {
    // Valid document should contain *single* query or mutation unless it's has a fragment
    if (
      doc.definitions.filter(
        (d) => d.kind === "OperationDefinition" && validDocDefOps.includes(d.operation)
      ).length !== 1
    ) {
      throw new Error("DocumentNode passed to Apollo Client must contain single query or mutation");
    }

    const definition = doc.definitions[0];
    if (!definition) throw new Error("definition undefined");

    // Valid document should contain *OperationDefinition*
    if (definition.kind !== "OperationDefinition") {
      throw new Error("DocumentNode passed to Apollo Client must contain single query or mutation");
    }

    switch (definition.operation) {
      case "mutation": {
        // For some reason the type of V is now throwing a warning, but the apollo client is working as expected.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const response = await client.mutate<R, V>({
          mutation: doc,
          variables
        });

        //if (response.errors) {
        //  throw new ApolloRequestError(response.errors);
        //}

        if (response.data === undefined || response.data === null) {
          throw new Error("No data presented in the GraphQL response");
        }

        return response.data;
      }
      case "query": {
        let returnedData = {};
        let error;
        let body = "";
        let queryMatch: RegExpMatchArray | null = null;
        let queryName = "";
        let logMessage = "";
        try {
          let startTime;
          if (process.env.APOLLO_QUERY_PERFORMANCE_REVIEW_ENABLED) {
            startTime = performance.now();
          }
          // For some reason the type of V is now throwing a warning, but the apollo client is working as expected.
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          const response = await client.query<R, V>({
            query: doc,
            variables,
            ...options
          });
          if (response.data === undefined || response.data === null) {
            throw new Error(
              `No data presented in the GraphQL response ${JSON.stringify(response.errors)}`
            );
          }
          if (
            process.env.APOLLO_QUERY_LOGGING_ENABLED ||
            process.env.APOLLO_QUERY_PERFORMANCE_REVIEW_ENABLED
          ) {
            let duration = "";
            if (startTime) {
              const endTime = performance.now();
              duration = `\u001b[1m${((endTime - startTime) / 1000).toFixed(6)}\u001b[22ms`;
            }
            body = doc.loc?.source?.body ?? "";
            queryMatch = body.match(/query\s+(.*?)\(/);
            queryName = queryMatch ? (queryMatch[1] as string) : "UNKNOWN";
            logMessage = `${duration} | \u001b[1m${queryName}\u001b[22m`;
            log(`\x1b[94m[QUERY]: ${logMessage}\x1b[0m`);
            process.env.APOLLO_QUERY_LOGGING_ENABLED &&
              log({
                variables,
                query: { body: doc.loc?.source.body },
                options,
                responseData: response.data
              });
          }
          returnedData = response.data;
        } catch (e) {
          error = e as ApolloError;
        }

        if (error) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore ServerParseError does seem to have `bodyText`
          const { bodyText = "", statusCode = 200 } = error.networkError ?? {};
          const { message = "" } = error;
          if (statusCode === 400 || message.includes("Unexpected token")) {
            doc && typeof doc === "object" && logError(`Query: ${JSON.stringify(doc)}`);
            variables &&
              typeof variables === "object" &&
              logError(`Query variables: ${JSON.stringify(variables) as string}`);
            throw new Error(
              `(${String(
                statusCode
              )}) GQL query response is not JSON or not parsable: \n response body: ${
                bodyText as string
              }`
            );
          } else {
            log(`\x1b[94m[ERROR ON QUERY]: ${logMessage}\x1b[0m`);
            log({
              variables,
              query: { body: doc.loc?.source.body },
              options
            });
            throw new Error(`Error with Apollo request/query ${error.message}`);
          }
        }

        return returnedData as R;
      }
      case "subscription": {
        throw new Error("Subscription requests through SDK interface are not supported");
      }
      default: {
        throw new Error(`unknown operation type ${definition.operation}`);
      }
    }
  };

  return getSdk(requester);
}
