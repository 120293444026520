import { ReactChild, ReactNode } from "react";
import { Box, Card, CardProps, Stack } from "@mui/material";

import { defaultCardMargin, defaultCardPadding } from "layout/configuration";

import { PageScrollAnchor } from "common/components/PageScrollAnchor";
import { ContentCardHeader } from "./ContentCardHeader";

export const CONTENT_CARD_DEFAULT_CONTENT_GAP = 4;
export interface ContentCardProps extends Omit<CardProps, "title"> {
  anchorIds?: string[] | string;
  attribution?: ReactNode;
  caption?: string;
  children?: ReactNode;
  contentGap?: number;
  gap?: number;
  hasNavigationTriggeredLoading?: boolean;
  headerChildren?: ReactChild;
  headerSkeleton?: boolean;
  headerVariant?: "primary" | "secondary";
  id?: string;
  noHeader?: boolean;
  subtitle?: string;
  title?: string;
}

export const contentCardStyle = (sx: ContentCardProps["sx"] = {}) => ({
  mx: defaultCardMargin,
  p: defaultCardPadding,
  borderWidth: {
    xs: 0,
    md: 1
  },
  borderRadius: 0,
  ...sx
});

export const ContentCard: React.FC<ContentCardProps> = ({
  anchorIds,
  attribution,
  caption,
  children,
  className = "",
  contentGap = CONTENT_CARD_DEFAULT_CONTENT_GAP,
  headerChildren,
  headerSkeleton = false,
  headerVariant = "secondary",
  noHeader = false,
  subtitle,
  sx,
  title,
  ...cardProps
}) => {
  return (
    <Card
      sx={contentCardStyle(sx)}
      component="section"
      variant="outlined"
      {...cardProps}
      className={`${className} content-card`}
    >
      {anchorIds &&
        Array(anchorIds)
          .flat()
          .map((id) => <PageScrollAnchor key={id} id={id} />)}
      <Stack gap={contentGap}>
        {!noHeader && (
          <Box
            sx={{ display: "flex", flexDirection: "column" }}
            className="content-card-header"
            id="content-card-header"
            component="header"
          >
            <ContentCardHeader
              variant={headerVariant}
              title={title}
              subtitle={subtitle}
              supertitle={caption}
              skeleton={headerSkeleton}
            >
              {headerChildren}
            </ContentCardHeader>
            {attribution}
          </Box>
        )}
        {children}
      </Stack>
    </Card>
  );
};
