import uniqueId from "lodash/uniqueId";

import { MhcStatIdentifier } from "graphqlApi/types";

import { Domain } from "../GeoMap/utils";
import { calculateClippedRange } from "./util/getClippedRange";
import { GetColorBasedOnValueProps } from "./util/getColorBasedOnValue";
import { getInvestigationForIdentifier } from "./util/getInvestigationForIdentifier";
import { formatValueByUnit } from "common/util/formatHelpers";
import { getGeographyEnumFromReadableName } from "common/util/geographyHelpers";

import { GeoMapLegend, GeoMapLegendProps, LegendItem } from "../GeoMap/Legend/GeoMapLegend";
import { InvestigateRange } from "./styles/investigateMapStyles";
import { InvestigateMapProps } from "./InvestigateMap";

interface InvestigateMapLegendWrapperProps extends Omit<GeoMapLegendProps, "id"> {
  ignoreFeatureValues: InvestigateMapProps["ignoreFeatureValues"];
  colorScale: InvestigateMapProps["colorScale"];
  selectedSi?: MhcStatIdentifier;
  selectedRangeForLegend: InvestigateRange[];
  investigations: InvestigateMapProps["investigations"];
  selectedGranularity: string;
  allValues: GetColorBasedOnValueProps["allValues"];
}

export const InvestigateMapLegendWrapper = ({
  ignoreFeatureValues,
  colorScale,
  selectedSi,
  selectedRangeForLegend,
  investigations,
  selectedGranularity,
  allValues,
  ...props
}: InvestigateMapLegendWrapperProps) => {
  if (ignoreFeatureValues) return <></>;
  const useContinuousScale = colorScale === "continuous";
  const subtitle = selectedSi && selectedSi.subtitle;
  let items: LegendItem[] = [];
  let minMax: { minValue: number | null; maxValue: number | null } = {
    minValue: 0,
    maxValue: null
  };
  let isClipped = true;
  if (!useContinuousScale) {
    items = selectedRangeForLegend.map(
      (range): LegendItem => ({
        color: range.color || "black",
        title:
          range.rangeString !== "Unavailable"
            ? `${formatValueByUnit({
                value: range.min,
                unit: selectedSi?.unit,
                precision: selectedSi?.precision
              })}${
                range?.max !== undefined
                  ? ` - ${formatValueByUnit({
                      value: range.max,
                      unit: selectedSi?.unit,
                      precision: selectedSi?.precision
                    })}`
                  : "+"
              }`
            : "Unavailable"
      })
    );
  } else {
    const investigation = getInvestigationForIdentifier({
      selectedStatId: selectedSi?.id,
      investigations
    });
    const geoEnum = getGeographyEnumFromReadableName(selectedGranularity);
    const investigationMinMax =
      investigation?.minMax && geoEnum ? investigation?.minMax[geoEnum] : undefined;
    const isPercentAndMaxIs100 = selectedSi?.isPercent && investigationMinMax?.maxValue === 100;
    isClipped = !isPercentAndMaxIs100;
    if (!isPercentAndMaxIs100 && allValues?.length && selectedSi?.id) {
      const domain = calculateClippedRange(allValues) as Domain;
      domain && (minMax = { minValue: domain[0], maxValue: domain[1] });
      !domain && investigationMinMax && (minMax = investigationMinMax);
    } else if (investigationMinMax) {
      minMax = investigationMinMax;
    }
  }
  const title = (subtitle || selectedSi?.name) ?? undefined;
  return (
    <>
      {title && (
        <GeoMapLegend
          id={selectedSi?.id ?? uniqueId()}
          width={"130px"}
          legendTitle={title}
          isClipped={isClipped}
          isPercent={selectedSi?.isPercent}
          isCurrency={selectedSi?.unit === "dollars"}
          items={items}
          {...minMax}
          {...props}
        />
      )}
    </>
  );
};
