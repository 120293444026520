import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import { Box, Typography } from "@mui/material";

export const PdfSupressionContext: React.FC = () => {
  const color = "rgba(0, 0, 0, 0.54)";
  const containerSx = { display: "flex", alignItems: "center" };
  return (
    <Box className="print-only suppression-context" sx={containerSx}>
      <Typography color={color} display="block">
        {" "}
        Values marked with{" "}
      </Typography>
      <Box ml={0.5} sx={containerSx} className="svg-icon-wrapper">
        {" "}
        <VisibilityOffOutlined color="disabled" />
      </Box>
      <Typography color={color} display="block">
        indicate there is insufficient data to respect the privacy of citizens.
      </Typography>
    </Box>
  );
};
