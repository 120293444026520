import { Box, BoxProps, Typography } from "@mui/material";
import uniqBy from "lodash/uniqBy";

import { MhcTopicFragment } from "graphqlApi/types";

import { UnstyledList } from "common/components/lists/UnstyledList";
import { AssociatedTopic } from "./AssociatedTopic";

interface Props extends BoxProps {
  topics: MhcTopicFragment[];
  showTitle?: boolean;
}

export const AssociatedTopicsList: React.FC<Props> = ({ topics, showTitle = true, ...props }) => {
  if (topics.length === 0) return null;
  const display = uniqBy(topics, (a) => a.id);
  return (
    <Box {...props}>
      {showTitle && (
        <Typography variant="subtitle2" mb={1} component="h5">
          Associated {display.length > 1 ? "Topics" : "Topic"}:
        </Typography>
      )}
      <UnstyledList>
        {display.map((topic) => (
          <AssociatedTopic topic={topic} key={topic.id} component="li" />
        ))}
      </UnstyledList>
    </Box>
  );
};
