import { ReactNode } from "react";
import Close from "@mui/icons-material/Close";
import { Box, Button, IconButton, Paper, Toolbar, Typography } from "@mui/material";
// Name Import is causing errors, only for the Drawer component 🤷🏼‍♂️
import Drawer from "@mui/material/Drawer";

import { SITE_HEADER_HEIGHT } from "layout/configuration";

import { SiteTopNavMHCLogo } from "layout/SiteTopNav/MyHealthyCommunityLogo";
import { SIDEBAR_WIDTH } from "./DashboardContentContainer";

interface Props {
  children: ReactNode;
  desktopNavIsOpen: boolean;
  mobileNavIsOpen: boolean;
  handleCloseMobileMenu: VoidFunction;
  handleCloseDesktopMenu: VoidFunction;
  window?: () => Window;
}

const DashboardContentNav: React.FC<Props> = ({
  children,
  desktopNavIsOpen,
  mobileNavIsOpen,
  handleCloseMobileMenu,
  handleCloseDesktopMenu,
  window
}) => {
  const drawerWidth = "100vw";
  const container = window !== undefined ? () => window().document.body : undefined;
  const headerHeight = `${SITE_HEADER_HEIGHT}px`;
  const height = `calc(100vh - ${headerHeight})`;
  return (
    <Box sx={{ display: "flex", top: headerHeight, position: "sticky", height }}>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileNavIsOpen}
        onClose={handleCloseMobileMenu}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          zIndex: 1300,
          display: { md: "block", lg: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth }
        }}
      >
        <Toolbar
          sx={{
            borderBottom: 1,
            borderBottomColor: "borders.light",
            justifyContent: "space-between",
            position: "sticky",
            top: 0,
            backgroundColor: "background.paper",
            zIndex: 2
          }}
        >
          <Box>
            <SiteTopNavMHCLogo />
          </Box>
          <IconButton onClick={handleCloseMobileMenu} aria-label="Close topic navigation">
            <Close />
          </IconButton>
        </Toolbar>
        <Box>{children}</Box>
      </Drawer>
      <Drawer
        anchor="left"
        open={desktopNavIsOpen}
        onClose={handleCloseDesktopMenu}
        variant="persistent"
        elevation={0}
        sx={(theme) => ({
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
          overflow: "hidden",
          transition: "transform .25s ease-in-out",
          zIndex: theme.zIndex.modal - 1,
          maxHeight: `calc(100vh - ${SITE_HEADER_HEIGHT}px)`,
          flex: 1,
          "& > .MuiPaper-root": {
            flex: 1,
            top: "auto",
            position: "static",
            boxShadow: "none",
            width: SIDEBAR_WIDTH,
            visibility: "visible !important",
            transform: desktopNavIsOpen ? "" : `translateX(-${SIDEBAR_WIDTH}px) !important`,
            transition: "transform .25s ease-in-out"
          }
        })}
      >
        <Box component={Paper} square elevation={5} flex={1}>
          <Box display="flex" alignItems="center" mb={2} p={2} sx={{ height: 50 }}>
            <Typography>Menu</Typography>
            {desktopNavIsOpen && (
              <Button
                aria-label="Close Menu"
                color="dark"
                onClick={handleCloseDesktopMenu}
                sx={{ ml: "auto" }}
                endIcon={<Close />}
              >
                Close
              </Button>
            )}
          </Box>
          {children}
        </Box>
      </Drawer>
    </Box>
  );
};

export default DashboardContentNav;
