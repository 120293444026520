import { useMemo } from "react";
import { Box, Stack, Typography } from "@mui/material";

import { formatDateByGranularity, formatValueByUnit } from "common/util/formatHelpers";
import { getUtcDateFromString } from "common/util/utcDateFromString";

import { MAP_SWATCH_BOX_SHADOW } from "../mapStyles";
import { GeoMapPopoverResult } from "./GeoMapPopover";

const Result: React.FC<GeoMapPopoverResult> = ({
  date: _date,
  granularity,
  nameOfIndicator,
  colorCode,
  colorCodeBorder,
  statIdentifier,
  formattedResult,
  result,
  index
}) => {
  const date: string | null = useMemo(() => {
    try {
      return formatDateByGranularity({ value: getUtcDateFromString(_date), granularity }) ?? null;
    } catch {
      return _date ?? null;
    }
  }, [granularity, _date]);
  return (
    <Stack>
      <Stack sx={{ mb: 1 }} gap={1}>
        {date && <Typography variant="body2">{date}</Typography>}
        {nameOfIndicator && (
          <Typography
            variant="body2"
            color="light.primary"
            fontWeight={600}
            data-testid={`geo-map-popover-result-name-${index}`}
          >
            {nameOfIndicator}
          </Typography>
        )}
      </Stack>
      <Stack direction="row">
        {colorCode && (
          <Box
            sx={{
              background: colorCode,
              border: colorCodeBorder !== undefined ? `1px solid ${colorCodeBorder}` : undefined,
              boxShadow: MAP_SWATCH_BOX_SHADOW,
              mr: "10px",
              width: "10px"
            }}
          />
        )}
        <Stack>
          {(result !== undefined || formattedResult !== undefined) && (
            <Typography
              variant="h4"
              fontWeight={600}
              data-testid={`geo-map-popover-result-value-${index}`}
            >
              {formattedResult ||
                formatValueByUnit({
                  value: result,
                  unit: statIdentifier?.unit,
                  precision: statIdentifier?.precision,
                  isPercent: statIdentifier?.isPercent ?? false
                })}{" "}
              <Typography variant="body2" color="light.primary" fontWeight={400} component="span">
                {statIdentifier?.statCaption}
              </Typography>
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Result;
