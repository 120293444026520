import { noDataMapColor } from "common/components/GeoMap/mapStyles";

export interface InvestigateRange {
  min: number;
  max?: number;
  rangeString: string;
  condition: (comparison: number) => boolean;
  color: string;
}

export type MapColorScales = "discrete" | "continuous";

export type MapColorPalette = [string, string, string, string, string];

type MapColorPaletteNames = "blue" | "green";
export const mapColorPalettes: Record<MapColorPaletteNames, MapColorPalette> = {
  blue: ["#F1EEF6", "#BDC9E1", "#74A9CF", "#2B8CBE", "#045A8D"],
  green: ["#F9FBF3", "#D0D8C2", "#B3C48F", "#6A970D", "#344C00"]
};

const notAvailableRange: InvestigateRange = {
  min: 0,
  max: 0,
  rangeString: "Unavailable",
  condition: (comparison: number) => comparison >= 0 && comparison <= 20,
  color: noDataMapColor
};

export const rangesUpToAHundred = (
  colors: MapColorPalette = mapColorPalettes.blue
): InvestigateRange[] => [
  {
    min: 0,
    max: 20,
    rangeString: "0 - 20",
    condition: (comparison: number) => comparison >= 0 && comparison <= 20,
    color: colors[0]
  },
  {
    min: 20,
    max: 40,
    rangeString: "20 - 40",
    condition: (comparison: number) => comparison > 20 && comparison <= 40,
    color: colors[1]
  },
  {
    min: 40,
    max: 60,
    rangeString: "40 - 60",
    condition: (comparison: number) => comparison > 40 && comparison <= 60,
    color: colors[2]
  },
  {
    min: 60,
    max: 80,
    rangeString: "60 - 80",
    condition: (comparison: number) => comparison > 60 && comparison <= 80,
    color: colors[3]
  },
  {
    min: 80,
    rangeString: "80+",
    condition: (comparison: number) => comparison > 80,
    color: colors[4]
  },
  notAvailableRange
];

export const rangesUpToTwenty = (
  colors: MapColorPalette = mapColorPalettes.blue
): InvestigateRange[] => [
  {
    min: 0,
    max: 5,
    rangeString: "0 - 5",
    condition: (comparison: number) => comparison >= 0 && comparison <= 5,
    color: colors[0]
  },
  {
    min: 5,
    max: 10,
    rangeString: "5 - 10",
    condition: (comparison: number) => comparison > 5 && comparison <= 10,
    color: colors[1]
  },
  {
    min: 10,
    max: 15,
    rangeString: "10 - 15",
    condition: (comparison: number) => comparison > 10 && comparison <= 15,
    color: colors[2]
  },
  {
    min: 15,
    max: 20,
    rangeString: "15 - 20",
    condition: (comparison: number) => comparison > 15 && comparison <= 20,
    color: colors[3]
  },
  {
    min: 20,
    rangeString: "20+",
    condition: (comparison: number) => comparison > 20,
    color: colors[4]
  },
  notAvailableRange
];

export const rangesUpToFifteen = (
  colors: MapColorPalette = mapColorPalettes.blue
): InvestigateRange[] => [
  {
    min: 0,
    max: 3,
    rangeString: "0 - 3",
    condition: (comparison: number) => comparison >= 0 && comparison <= 3,
    color: colors[0]
  },
  {
    min: 3,
    max: 6,
    rangeString: "3 - 6",
    condition: (comparison: number) => comparison > 3 && comparison <= 6,
    color: colors[1]
  },
  {
    min: 6,
    max: 9,
    rangeString: "6 - 9",
    condition: (comparison: number) => comparison > 6 && comparison <= 9,
    color: colors[2]
  },
  {
    min: 9,
    max: 12,
    rangeString: "9 - 12",
    condition: (comparison: number) => comparison > 9 && comparison <= 12,
    color: colors[3]
  },
  {
    min: 12,
    rangeString: "12+",
    condition: (comparison: number) => comparison > 12,
    color: colors[4]
  },
  notAvailableRange
];

export const rangesUpToFifty = (
  colors: MapColorPalette = mapColorPalettes.blue
): InvestigateRange[] => [
  {
    min: 0,
    max: 10,
    rangeString: "0 - 10",
    condition: (comparison: number) => comparison >= 0 && comparison <= 10,
    color: colors[0]
  },
  {
    min: 10,
    max: 20,
    rangeString: "10 - 20",
    condition: (comparison: number) => comparison > 10 && comparison <= 20,
    color: colors[1]
  },
  {
    min: 20,
    max: 30,
    rangeString: "20 - 30",
    condition: (comparison: number) => comparison > 20 && comparison <= 30,
    color: colors[2]
  },
  {
    min: 30,
    max: 40,
    rangeString: "30 - 40",
    condition: (comparison: number) => comparison > 30 && comparison <= 40,
    color: colors[3]
  },
  {
    min: 40,
    rangeString: "40+",
    condition: (comparison: number) => comparison > 40,
    color: colors[4]
  },
  notAvailableRange
];

export const rangesUpToFiveHundred = (
  colors: MapColorPalette = mapColorPalettes.blue
): InvestigateRange[] => [
  {
    min: 0,
    max: 100,
    rangeString: "0 - 100",
    condition: (comparison: number) => comparison >= 0 && comparison <= 100,
    color: colors[0]
  },
  {
    min: 100,
    max: 200,
    rangeString: "100 - 200",
    condition: (comparison: number) => comparison > 100 && comparison <= 200,
    color: colors[1]
  },
  {
    min: 200,
    max: 300,
    rangeString: "200 - 300",
    condition: (comparison: number) => comparison > 200 && comparison <= 300,
    color: colors[2]
  },
  {
    min: 300,
    max: 400,
    rangeString: "300 - 400",
    condition: (comparison: number) => comparison > 300 && comparison <= 400,
    color: colors[3]
  },
  {
    min: 400,
    rangeString: "400+",
    condition: (comparison: number) => comparison > 400,
    color: colors[4]
  },
  notAvailableRange
];

export const rangesUpToThreeHundred = (
  colors: MapColorPalette = mapColorPalettes.blue
): InvestigateRange[] => [
  {
    min: 0,
    max: 60,
    rangeString: "0 - 60",
    condition: (comparison: number) => comparison >= 0 && comparison <= 60,
    color: colors[0]
  },
  {
    min: 60,
    max: 120,
    rangeString: "60 - 120",
    condition: (comparison: number) => comparison > 60 && comparison <= 120,
    color: colors[1]
  },
  {
    min: 120,
    max: 180,
    rangeString: "120 - 180",
    condition: (comparison: number) => comparison > 120 && comparison <= 180,
    color: colors[2]
  },
  {
    min: 180,
    max: 240,
    rangeString: "180 - 240",
    condition: (comparison: number) => comparison > 180 && comparison <= 240,
    color: colors[3]
  },
  {
    min: 240,
    rangeString: "240+",
    condition: (comparison: number) => {
      return comparison > 240;
    },
    color: colors[4]
  },
  notAvailableRange
];

export const rangesUpToThousand = (
  colors: MapColorPalette = mapColorPalettes.blue
): InvestigateRange[] => [
  {
    min: 0,
    max: 250,
    rangeString: "0 - 250",
    condition: (comparison: number) => comparison >= 0 && comparison <= 250,
    color: colors[0]
  },
  {
    min: 251,
    max: 500,
    rangeString: "251 - 500",
    condition: (comparison: number) => comparison > 250 && comparison <= 500,
    color: colors[1]
  },
  {
    min: 501,
    max: 750,
    rangeString: "501 - 750",
    condition: (comparison: number) => comparison > 500 && comparison <= 750,
    color: colors[2]
  },
  {
    min: 751,
    max: 1000,
    rangeString: "750 - 1000",
    condition: (comparison: number) => comparison > 750 && comparison <= 1000,
    color: colors[3]
  },
  {
    min: 1001,
    rangeString: "1,000+",
    condition: (comparison: number) => comparison > 1000,
    color: colors[4]
  },
  notAvailableRange
];

const possibleRangesArray = [
  "upto_fifteen",
  "upto_twenty",
  "upto_fifty",
  "upto_hundred",
  "upto_three_hundred",
  "upto_five_hundred",
  "upto_thousand"
] as const;
export type PossibleRanges = (typeof possibleRangesArray)[number];
export const RangeOptions: {
  [key in PossibleRanges]: (colors?: MapColorPalette) => InvestigateRange[];
} = {
  upto_fifteen: rangesUpToFifteen,
  upto_twenty: rangesUpToTwenty,
  upto_fifty: rangesUpToFifty,
  upto_hundred: rangesUpToAHundred,
  upto_three_hundred: rangesUpToThreeHundred,
  upto_five_hundred: rangesUpToFiveHundred,
  upto_thousand: rangesUpToThousand
};
