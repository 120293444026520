import { InvestigateMapAvailableGeographies } from "./types";
import { MhcFeatureCollection } from "graphqlApi/types";

import { getReadableGeographyName } from "common/util/geographyHelpers";

export const getSelectedGeoJson = (
  availableGeographies: InvestigateMapAvailableGeographies[],
  mapType: string
): MhcFeatureCollection | undefined => {
  const r = availableGeographies.filter(
    (option) => getReadableGeographyName(option.geography) === mapType
  );
  if (r.length > 0) {
    return r[0]?.geoJSON;
  }
  return undefined;
};
