import { Box } from "@mui/material";

import { SliderWrapperProps } from "./types/types";

import SliderLabels from "./SliderLabels";

export const SliderWrapper: React.FC<SliderWrapperProps> = ({
  children,
  leftSubtitle,
  rightSubtitle,
  centerSubtitle,
  width,
  labelSx
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: width }}>
      {children}
      <SliderLabels labels={[leftSubtitle, centerSubtitle, rightSubtitle]} sx={labelSx} />
    </Box>
  );
};
