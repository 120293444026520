"use client";

import { Stack } from "@mui/material";

import { CardLoadingIndicator } from "layout/card/CardLoadingIndicator";
import { ContentCard, ContentCardProps } from "layout/card/ContentCard";
import { useNavigationStore } from "common/state/useNavigationStore";

export const TopicContentCard: React.FC<ContentCardProps> = ({
  title,
  sx,
  subtitle,
  children,
  gap,
  ...props
}) => {
  const isNavigating = useNavigationStore((store) => store.isNavigating);
  return (
    <ContentCard title={title} sx={{ position: "relative", ...sx }} subtitle={subtitle} {...props}>
      {isNavigating && <CardLoadingIndicator />}
      <Stack gap={gap ?? 5}>{children}</Stack>
    </ContentCard>
  );
};
