type UpdateLocationOnCurrentPath = {
  /** Path with param keys as `[<key>]`  */
  pathname: string | null;
  locationId?: string;
  params: Record<string, string | string[]>;
};
export const updateLocationOnCurrentPath = ({
  pathname,
  locationId,
  params = {}
}: UpdateLocationOnCurrentPath) => {
  if (!locationId || !pathname) return "";
  const basePathname = pathname;
  let newPath = basePathname;
  if (params.locationId) {
    newPath = newPath.replace("[locationId]", locationId);
  } else if (basePathname.includes("state")) {
    newPath = newPath.replace("state", locationId);
  }
  if (basePathname === "/portals/ecdc/education") {
    newPath = `/portals/ecdc/education/${locationId}`;
  } else if (basePathname === "/portals/ecdc") {
    newPath = `/portals/ecdc/locations/${locationId}`;
  } else if (basePathname === "/portals/cpr") {
    newPath = `/portals/cpr/${locationId}`;
  }
  Object.entries(params).forEach(([key, value]) => {
    if (typeof key !== "string" || typeof value !== "string") return;
    if (key !== "locationId") {
      newPath = newPath.replace(`[${key}]`, value);
    }
  });
  return newPath;
};
