import { MhcLocationStat, MhcStatIdentifierFragment, MhcTarget } from "graphqlApi/types";

import { KPITargetExceptions } from "modules/Topics/constants";
import { calculateChangeFromTimeSeries } from "@/CPR/util/calculators";
import { isImproving, needsAttention } from "common/util/formatHelpers/statIdentifierHelpers";

import { KpiTargetProps } from "common/components/KPI/KpiTarget";
import { LoadedDateSeries } from "../fetchStatsForAllSections";

interface CreateTargetPropsType {
  /** Last value of the stat  */
  lastValue: number | null | undefined;
  /** Percentage change between first date with data and last  */
  percentageChange?: string | null;
  /** Stat identifier target  */
  siTarget: MhcTarget;
  /** Stat identifier object  */
  statIdentifier: MhcStatIdentifierFragment;
  /** Sentence generated for stat  */
  statSentence?: MhcLocationStat["sentence"];
  /** Time series (values/dates)  */
  timeSeries: LoadedDateSeries | undefined | null;
}

/**
 * Creates the props for the KPI Target component
 *
 * @param params
 * @param params.lastValue - Last value of the stat
 * @param params.percentageChange - Percentage change of the stat
 * @param params.siTarget - Target for the stat
 * @param params.statIdentifier - Stat identifier associated with the stat
 * @param params.statSentence - Sentence generated for the stat
 * @param params.timeSeries - Time series data for the stat
 *
 * @returns Props for KpiTarget component as KpiTargetProps
 *
 */
const createTargetProps = ({
  lastValue,
  percentageChange,
  siTarget,
  statIdentifier,
  statSentence,
  timeSeries
}: CreateTargetPropsType): KpiTargetProps => {
  const { precision, improvement, subtitle, unit, id, name, isPercent, ageAdjusted, statType } =
    statIdentifier;
  return {
    statSentence,
    target: siTarget,
    currentValue: lastValue as number,
    targetMet: !needsAttention({
      currentValue: lastValue,
      targetValue: siTarget.value,
      improvementType: improvement
    }),
    hideLongStatement:
      ((KPITargetExceptions[statIdentifier.id] ?? undefined)?.hideLongStatement as
        | boolean
        | undefined) ?? false,
    isImproving: timeSeries
      ? isImproving({
          change: calculateChangeFromTimeSeries(timeSeries.values),
          improvementType: improvement
        })
      : undefined,
    percentageChange,
    statIdentifier: {
      statType,
      improvement,
      precision,
      subtitle,
      unit,
      id,
      name,
      isPercent,
      ageAdjusted
    }
  };
};

export default createTargetProps;
