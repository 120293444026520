import { Box } from "@mui/material";

type Props = {
  id: string;
};

// negative offset for sticky top bar
const defaultScrollOffset = -80;
export const PageScrollAnchor: React.FC<Props> = ({ id }) => {
  return <Box id={id} sx={{ position: "relative", top: defaultScrollOffset }} />;
};
