import { SxProps } from "@mui/material";

import { InvestigateMapProps } from "./types";

import { theme } from "theme/theme";

export const getInvestigateDropdownsSx = (
  displayDirection: InvestigateMapProps["displayDirection"]
): SxProps<typeof theme> => {
  return {
    mb: displayDirection === "column" ? "auto" : 2,
    display: "flex",
    flexDirection: "row",
    mr: displayDirection === "column" ? "auto" : undefined,
    pr: {
      xs: undefined,
      md: displayDirection === "column" ? 2 : undefined
    },
    height: displayDirection === "column" ? "min-content" : undefined
  };
};
