import { Box, Divider, Skeleton, Stack } from "@mui/material";

import IndicatorTableModalChartFallback from "common/components/IndicatorTable/IndicatorTableContentWrapper/IndicatorTableModalChartFallback";
import { StatAttributionListSkeleton } from "modules/ECDC/components/StatAttributionList/StatAttributionListSkeleton";

export const IndicatorModalSkeleton = () => {
  return (
    <>
      <IndicatorTableModalChartFallback />
      <Divider sx={{ my: 2.5 }} />
      <Skeleton variant="text" width="200px" />
      <Stack maxWidth="100ch" gap={1}>
        <Stack direction="row">
          <Skeleton variant="text" width="120px" />
        </Stack>
        <Skeleton variant="rectangular" width="250px" height="100px" />
      </Stack>
      <>
        <Divider sx={{ my: 2.5 }} />
        <Box component="article" data-testid="indicator-modal-attribution-list">
          <Skeleton variant="text" width="40px" />
          <StatAttributionListSkeleton showDivider={false} showTitle={false} controlWidth={false} />
        </Box>
      </>
    </>
  );
};
