import { BoxProps, Link, Stack } from "@mui/material";

import { MhcTopicFragment } from "graphqlApi/types";

export interface AssociatedTopicProps {
  topic: Omit<MhcTopicFragment, "__typename">;
  component: BoxProps["component"];
}

export const AssociatedTopic: React.FC<AssociatedTopicProps> = ({
  topic: { href, name },
  ...props
}) => {
  return (
    <>
      <Stack direction="row" spacing={1} sx={{ alignItems: "baseline" }} {...props}>
        {href ? (
          <Link href={href} target="_blank" rel="noopener noreferrer" variant="body2">
            {name}
          </Link>
        ) : (
          name
        )}
      </Stack>
    </>
  );
};
