import { ReactElement } from "react";
import { Box, CardProps, Grid, Stack, Typography } from "@mui/material";

import { DateFromApi } from "graphqlApi/customTypes";
import {
  MhcGeographyEnum,
  MhcStatIdentifierFragment,
  MhcTimeSeriesGranularityEnum
} from "graphqlApi/types";
import {
  AboutTheDataContent,
  StatAttributions
} from "modules/Topics/util/elementHelpers/dashboard/types";
import { KpiPropsAndRelatedKpis } from "modules/Topics/util/fetchingFunctions/kpi/types";

import { DEFAULT_COLUMN_SPACING } from "layout/configuration";
import { formatDateByGranularity } from "common/util/formatHelpers";
import { getUtcDateFromString } from "common/util/utcDateFromString";

import { AttributionPopover } from "modules/ECDC/components/AttributionPopover";
import { getFeaturedKpiProps } from "modules/Topics/components/TopicKpis";
import { KPI, KpiProps } from "../KPI";
import { generateChangePhrase } from "../KPI/KpiPercentSentence";

interface extendedKpi extends KpiProps {
  mdSize?: number;
}

export interface KpiGroupProps {
  kpis: extendedKpi[];
  aboutTheDataContent?: AboutTheDataContent | null;
  attributions?: StatAttributions;
  description?: null | string | ReactElement;
  granularity?: MhcTimeSeriesGranularityEnum | null;
  latestDate?: DateFromApi;
  showFullTitleWithPrimaryKpi?: boolean;
  showPercentChange?: boolean;
  statIdentifier?: MhcStatIdentifierFragment;
  supportedGeographies?: MhcGeographyEnum[] | null;
  sx?: CardProps["sx"];
  updatedDate?: number;
  showAboutTheData?: boolean;
  showAttributionsInAboutTheData?: boolean;
  attributionsByStat?: Record<string, StatAttributions>;
  hidePercentageChangeKpi?: boolean;
}

export const createPercentChangeKpiProps = (primaryKpi: KpiProps): KpiProps | null => {
  if (!primaryKpi.enhancement?.trendProps) return null;
  const { timeSeries: { values = [], dates = [] } = { value: [], dates: [] }, granularity } =
    primaryKpi.enhancement.trendProps;
  if (!values.length || !dates.length) return null;
  const changePhrase = generateChangePhrase({
    firstValue: values[0] as number,
    lastValue: values[values.length - 1] as number,
    startDate: dates[0] as string,
    endDate: dates[dates.length - 1] as string,
    granularity: granularity ?? undefined,
    pastTense: false,
    includeDateRange: false,
    valueFirst: true,
    capitalizePercentChange: false
  });
  const startDateString =
    formatDateByGranularity({ value: getUtcDateFromString(dates[0] as string), granularity }) ?? "";
  const endDateString =
    formatDateByGranularity({
      value: getUtcDateFromString(dates[dates.length - 1] as string),
      granularity
    }) ?? "";
  return {
    id: `PERCENTAGE_CHANGE_${primaryKpi.id}`,
    value: changePhrase,
    title: "Percentage change",
    enhancement: {
      dataDatePeriod:
        startDateString && endDateString ? `${startDateString} to ${endDateString}` : null
    },
    // Since the age-adjusted field is used to determine if the age-adjusted message
    // appears, and we don't want to display it for percentages this should be false
    ageAdjusted: false
  };
};

const KpiGroup: React.FC<KpiGroupProps> = ({
  description,
  kpis,
  attributions = [],
  showPercentChange = false,
  showFullTitleWithPrimaryKpi = false,
  showAboutTheData = false,
  hidePercentageChangeKpi = false
}) => {
  const kpiGrid = (() => {
    if (kpis.length > 1) {
      return kpis.map((props, i) => (
        <Grid
          item
          xs={12}
          md={props.mdSize ? props.mdSize : 6}
          key={`kpi-${i}`}
          sx={{ display: "flex" }}
        >
          <KPI {...props} sx={{ ...props.sx, mb: 0 }} />
        </Grid>
      ));
    } else {
      const kpiProps = kpis[0] as KpiProps;
      let { relatedKpis = [] } = kpiProps || {};
      if ((showPercentChange || relatedKpis.length < 2) && hidePercentageChangeKpi === false) {
        const percentChangeKpiProps = createPercentChangeKpiProps(kpis[0] as KpiProps);
        if (percentChangeKpiProps) {
          relatedKpis = relatedKpis.filter(
            ({ title }) => typeof title === "string" && !title.toLowerCase().includes("crude")
          );
          relatedKpis = [...relatedKpis, percentChangeKpiProps];
        }
      }
      const featuredKpiProps = getFeaturedKpiProps(kpiProps) as KpiPropsAndRelatedKpis;
      return (
        <Grid item container spacing={2}>
          <Grid item xs={12} display={"flex"}>
            <KPI
              {...featuredKpiProps}
              valueSize="x-large"
              fillContainer
              percentSentenceProps={
                showPercentChange ? null : featuredKpiProps.percentSentenceProps
              }
              title={
                showFullTitleWithPrimaryKpi && "kpiGroupTitle" in kpiProps ? (
                  <Typography component="span" fontWeight="inherit">
                    {featuredKpiProps.kpiGroupTitle}{" "}
                    <Typography fontWeight="normal" component="span">
                      ({featuredKpiProps.title})
                    </Typography>
                  </Typography>
                ) : (
                  featuredKpiProps.title
                )
              }
            />
          </Grid>
          {relatedKpis && relatedKpis.length > 0 && (
            <Grid container item xs={12} display={"flex"} spacing={2}>
              {relatedKpis.map((relatedKpiProps: KpiProps, i: number) => (
                <Grid key={i} item xs={12} md={6} display={"flex"}>
                  <KPI
                    {...relatedKpiProps}
                    valueSize="large"
                    sx={{ ...relatedKpiProps.sx, mb: 0 }}
                    fillContainer
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      );
    }
  })();

  return (
    <Stack>
      {showAboutTheData && attributions && attributions.length > 0 && (
        <Box ml="auto" mb={0.5}>
          <AttributionPopover attributions={attributions} uiLocation="KPI Group" />
        </Box>
      )}
      <Grid container spacing={DEFAULT_COLUMN_SPACING} alignItems="flex-start">
        {description && (
          <Grid item xs={12} lg={5}>
            <Typography>{description}</Typography>
          </Grid>
        )}
        <Grid container item xs={12} lg={description ? 7 : 12} alignItems="stretch" spacing={2}>
          {kpiGrid}
        </Grid>
      </Grid>
    </Stack>
  );
};

export default KpiGroup;
