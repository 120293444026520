import { Stack } from "@mui/material";

import { MhcAttributionFragment, MhcResource } from "graphqlApi/types";

import ResourcesAndAttributions from "../../components/TopicAdditionalInfo/ResourcesAndAttributions";
import AdditionalInfoPrivacyContent from "./PrivacyContent";

interface Props {
  attributions?: MhcAttributionFragment[];
  resources?: MhcResource[];
}

const DefaultAdditionalInfo: React.FC<Props> = ({ attributions = [], resources = [] }) => (
  <Stack gap={6}>
    <ResourcesAndAttributions resources={resources} attributions={attributions} />
    <AdditionalInfoPrivacyContent />
  </Stack>
);

export default DefaultAdditionalInfo;
