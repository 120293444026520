export const getSviRiskNameFromRowColumn = (
  colors: string[][],
  row?: number,
  column?: number
): { name: string | undefined; color: string | undefined } | undefined => {
  const valueMatrix = [
    // eslint-disable-next-line prettier/prettier
    ["Moderate", "Moderate to Low", "Low", "Low"],
    // eslint-disable-next-line prettier/prettier
    ["Moderate", "Moderate to Low", "Moderate to Low", "Low"],
    // eslint-disable-next-line prettier/prettier
    ["High", "Moderate", "Moderate to Low", "Low"],
    // eslint-disable-next-line prettier/prettier
    ["High", "High", "Moderate", "Low"]
  ];
  if (row === undefined || column === undefined) {
    return undefined;
  }
  const rowArray = valueMatrix[row];
  const rowArrayColor = colors[row];
  if (rowArray && rowArrayColor) {
    return { name: rowArray[column], color: rowArrayColor[column] };
  }
  return undefined;
};
