import { ReactNode } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { DEFAULT_MARGIN_STR, defaultContainerMargin } from "../configuration";
import { useIsMobile } from "common/util/hooks/useIsMobile";

import ContextHeader from "layout/ContextHeader/ContextHeader";
import DashboardContentNav from "./DashboardContentNav";

interface Props {
  children: ReactNode;
  sidebar?: ReactNode;
  header?: ReactNode;
  contextHeader?: ReactNode;
  desktopNavIsOpen: boolean;
  mobileNavIsOpen: boolean;
  handleOpenDesktopMenu: VoidFunction;
  handleCloseMobileMenu: VoidFunction;
  handleCloseDesktopMenu: VoidFunction;
  image?: StaticImageData;
  title?: string;
}

export const SIDEBAR_WIDTH = 300;

const DashboardContentContainer: React.FC<Props> = ({
  children,
  sidebar,
  header,
  contextHeader,
  desktopNavIsOpen,
  mobileNavIsOpen,
  handleOpenDesktopMenu,
  handleCloseMobileMenu,
  handleCloseDesktopMenu,
  title,
  image
}) => {
  const theme = useTheme();
  const maxPageWidth = theme.breakpoints.values.xl;
  const desktopContainerWidth = desktopNavIsOpen ? `calc(100vw - ${SIDEBAR_WIDTH}px)` : "100vw";
  const desktopTransform = desktopNavIsOpen ? "" : `translateX(-${SIDEBAR_WIDTH}px)`;
  const isMobile = useIsMobile();
  return (
    <Box
      sx={({ palette }) => ({
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        flex: "1 1 auto",
        backgroundColor: palette.component.background
      })}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flex: "1 1 100%",
          alignItems: "flex-start",
          maxWidth: "100%"
        }}
      >
        {sidebar && (
          <DashboardContentNav
            desktopNavIsOpen={desktopNavIsOpen}
            mobileNavIsOpen={mobileNavIsOpen}
            handleCloseMobileMenu={handleCloseMobileMenu}
            handleCloseDesktopMenu={handleCloseDesktopMenu}
          >
            {sidebar}
          </DashboardContentNav>
        )}
        <Box
          component="main"
          sx={{
            minWidth: { xs: "100vw", md: desktopContainerWidth },
            transition: "min-width .25s ease-in-out, transform .25s ease-in-out",
            transform: { xs: "none", md: desktopTransform }
          }}
        >
          {!isMobile && contextHeader && (
            <ContextHeader isOpen={desktopNavIsOpen} handleOpenMenu={handleOpenDesktopMenu}>
              {contextHeader}
            </ContextHeader>
          )}
          {header && !image && (
            <Paper elevation={0} square sx={{ background: theme.palette.common.white }}>
              <Box>{header}</Box>
            </Paper>
          )}
          {image && !header && (
            <Paper
              sx={{
                width: "100%",
                height: { md: "450px", xs: "300px", overflow: "hidden", position: "relative" }
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0,0,0,0.58)",
                  position: "absolute",
                  zIndex: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center"
                }}
              >
                {title && (
                  <Typography
                    fontSize={50}
                    fontWeight={800}
                    lineHeight={"128px"}
                    color="white"
                    component="p"
                    textAlign="center"
                  >
                    {title}
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundImage: `url(${image.src})`,
                  backgroundPosition: "center 40%",
                  backgroundSize: "cover",
                  zIndex: 1
                }}
              ></Box>
            </Paper>
          )}
          <Box
            sx={{
              px: defaultContainerMargin,
              py: DEFAULT_MARGIN_STR,
              maxWidth: { xs: "100vw", lg: `${maxPageWidth}px` }
            }}
          >
            <Box key="content">{children}</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardContentContainer;
