"use client";

import { useEffect, useMemo, useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import isNil from "lodash/isNil";

import { SectionedSliderProps } from "../Ranges/types/types";
import { BivariateHeatmapColors, BivariatePoint, BivariateQuadrant } from "./util/types";
import { MhcLocationFragment, MhcStatIdentifierFragment } from "graphqlApi/types";

import { buildColorArrayFromColorRange } from "./util/buildColorArrayFromColorRange";
import { DEFAULT_BIVARIATE_COLOR_MAP } from "./util/colors";
import { getRowColumnFromSelectedValue } from "./util/getRowColumnFromSelectedValue";
import { getSviRiskNameFromRowColumn } from "./util/getSviRiskNameFromRowColumn";
import { formatValueByUnit } from "common/util/formatHelpers";
import {
  determineRangeValuePhrase,
  RangeConditions
} from "common/util/formatHelpers/statIdentifierHelpers/ranges";

import { ChartStackAlert } from "../Alerts/ChartStackAlert";
import { SectionedSlider } from "../Ranges/SectionedSlider";
import { BivariateHeatmap, BivariateHeatmapProps } from "./BivariateHeatmap";
import { BivariateMap, BivariateMapProps } from "./BivariateMap";
import { BivariateParagraph, BivariateParagraphProps } from "./BivariateParagraph";
import { BivariateTable, BivariateTableProps } from "./BivariateTable";

export type OmittedBivariateMapProps =
  | "width"
  | "height"
  | "selectedBivariatePoint"
  | "setSelectedBivariatePoint";
export interface BivariateSectionProps {
  paragraph?: BivariateParagraphProps;
  mapWidth?: string;
  mapHeight?: string;
  mapProps: Omit<BivariateMapProps, OmittedBivariateMapProps>;
  squareProps: Omit<BivariateHeatmapProps, "selectedBivariatePoint" | "colors">;
  selectedLocation?: MhcLocationFragment;
  sviSliderProps?: Omit<SectionedSliderProps, "selectedValue"> | null;
  useYAxisForSelectedValue?: boolean;
  bivariateValueComparisonName?: string;
  bivariateValueComparisonOtherValueName?: string;
  referenceLink?: React.ReactNode;
  viewMoreInformationNode?: React.ReactNode;
  tableProps?: Omit<BivariateTableProps, "xAxisTitle" | "yAxisTitle" | "data">;
  heatmapColors?: BivariateHeatmapColors;
}

export const BivariateSection: React.FC<BivariateSectionProps> = ({
  paragraph,
  mapWidth = "100%",
  mapHeight = "100%",
  mapProps: { valueMap, xAxisResult, yAxisResult, locationReference, ...mapProps },
  squareProps: { xCategories, yCategories, ...squareProps },
  heatmapColors = DEFAULT_BIVARIATE_COLOR_MAP,
  selectedLocation,
  sviSliderProps,
  useYAxisForSelectedValue,
  bivariateValueComparisonName,
  bivariateValueComparisonOtherValueName,
  referenceLink,
  viewMoreInformationNode,
  tableProps
}) => {
  const [selectedBivariatePoint, setSelectedBivariatePoint] = useState<BivariatePoint | undefined>(
    valueMap[selectedLocation?.id ?? ""]
  );

  useEffect(() => {
    setSelectedBivariatePoint(valueMap[selectedLocation?.id ?? ""]);
  }, [valueMap, selectedLocation]);

  const selectedValue = useMemo(() => {
    if (isNil(selectedLocation)) {
      return undefined;
    }
    const value = useYAxisForSelectedValue
      ? valueMap[selectedLocation?.id]?.yAxis
      : valueMap[selectedLocation?.id]?.xAxis;
    const result = useYAxisForSelectedValue ? yAxisResult : xAxisResult;
    return {
      value,
      si: result.statIdentifier
    };
  }, [selectedLocation, useYAxisForSelectedValue, valueMap, xAxisResult, yAxisResult]);

  const colors = useMemo(() => {
    const { high, moderate, moderateToLow, low } = heatmapColors;
    return buildColorArrayFromColorRange(high, moderate, moderateToLow, low);
  }, [heatmapColors]);

  const otherAxisSelectedValue = useMemo(() => {
    if (selectedLocation === undefined) {
      return undefined;
    }
    const value = !useYAxisForSelectedValue
      ? valueMap[selectedLocation?.id]?.yAxis
      : valueMap[selectedLocation?.id]?.xAxis;
    const result = !useYAxisForSelectedValue ? yAxisResult : xAxisResult;
    return {
      value,
      si: result.statIdentifier
    };
  }, [selectedLocation, useYAxisForSelectedValue, valueMap, xAxisResult, yAxisResult]);

  const bivariateTableData: BivariateTableProps["data"] = useMemo(() => {
    const keys = Object.keys(valueMap);
    const data: BivariateTableProps["data"] = [];
    keys.sort().forEach((key) => {
      const value = valueMap[key];
      const location = locationReference[key];
      if (value !== undefined && location !== undefined) {
        const sviRowColumns = getRowColumnFromSelectedValue(value, xCategories, yCategories);
        const svi = getSviRiskNameFromRowColumn(colors, sviRowColumns?.row, sviRowColumns?.column);
        data.push({
          locationName: location.name,
          xAxisValue: `${formatValueByUnit({
            value: value.xAxis,
            unit: xAxisResult.statIdentifier?.unit,
            precision: xAxisResult.statIdentifier?.precision,
            isPercent: xAxisResult.statIdentifier?.isPercent ?? false
          })}`,
          yAxisValue: `${formatValueByUnit({
            value: value.yAxis,
            unit: yAxisResult.statIdentifier?.unit,
            precision: yAxisResult.statIdentifier?.precision,
            isPercent: xAxisResult.statIdentifier?.isPercent ?? false
          })}`,
          evaluationScore: svi?.name,
          backgroundColor: svi?.color,
          id: key
        });
      }
    });
    return data;
  }, [
    valueMap,
    locationReference,
    xCategories,
    yCategories,
    colors,
    xAxisResult.statIdentifier?.unit,
    xAxisResult.statIdentifier?.precision,
    xAxisResult.statIdentifier?.isPercent,
    yAxisResult.statIdentifier?.unit,
    yAxisResult.statIdentifier?.precision
  ]);

  const [value, formattedValue] = useMemo(() => {
    const { value = null } = selectedValue ?? { value: null };
    let formattedValue: string | number | null = value;
    if (selectedValue && value) {
      const { unit, isPercent, precision } = selectedValue.si as MhcStatIdentifierFragment;
      value && (formattedValue = formatValueByUnit({ value, unit, isPercent, precision }));
    }
    return [value, formattedValue];
    // We need to include `selectedLocation` in case it changes and `sviSliderProps`
    // in case the component without the slider is re-rendered with it
    // (the value changes from null to {...})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, sviSliderProps, selectedValue]);

  const quadrants = useYAxisForSelectedValue ? yCategories : xCategories;
  const getQuadrantVal = (index: number, minOrMax: "min" | "max"): number => {
    const quadrant = (quadrants[index] as BivariateQuadrant) ?? null;
    return quadrant ? quadrant[minOrMax] ?? 0 : 0;
  };
  const rangeConditions: RangeConditions = {
    Low: (v: number) => v > getQuadrantVal(0, "min") && v <= getQuadrantVal(0, "max"),
    ModerateToLow: (v: number) => v > getQuadrantVal(1, "min") && v <= getQuadrantVal(1, "max"),
    Moderate: (v: number) => v > getQuadrantVal(2, "min") && v <= getQuadrantVal(2, "max"),
    High: (v: number) => v > getQuadrantVal(3, "min")
  };

  const showLocationDetail = useMemo(() => {
    return selectedLocation && sviSliderProps;
  }, [selectedLocation, sviSliderProps]);

  if (
    (showLocationDetail && !value) ||
    (!showLocationDetail &&
      Object.values(valueMap).every((v) => (v.xAxis === null && v.yAxis === null) || v === null))
  ) {
    return <ChartStackAlert />;
  }

  return (
    <Grid container spacing={4}>
      {showLocationDetail && (
        <Grid item xs={12} lg={6}>
          <Typography variant="h4" fontWeight={400} color="light.primary" mb={2}>
            {paragraph?.title}
          </Typography>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography py={0}>
              {selectedLocation?.name} is characterized by having a {bivariateValueComparisonName}{" "}
              of <strong>{formattedValue || value}</strong> which indicates{" "}
              <strong>
                {determineRangeValuePhrase({
                  value: value as number,
                  conditions: rangeConditions
                })?.toLocaleLowerCase()}
              </strong>{" "}
              levels of vulnerability.
            </Typography>
            {referenceLink}
            <Box mt={1} mb={3}>
              <SectionedSlider
                {...(sviSliderProps as SectionedSliderProps)}
                selectedValue={selectedValue?.value}
                selectedFormattedValue={formattedValue || undefined}
              />
            </Box>
            {viewMoreInformationNode}
            <Typography py={0}>
              As of the last data reporting, the {bivariateValueComparisonOtherValueName} for{" "}
              {selectedLocation?.name} was{" "}
              {formatValueByUnit({
                value: otherAxisSelectedValue?.value,
                unit: otherAxisSelectedValue?.si?.unit,
                precision: otherAxisSelectedValue?.si?.precision,
                isPercent: otherAxisSelectedValue?.si?.isPercent ?? false
              })}
              .
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid item xs={12} lg={6}>
        <Stack height="100%" gap={4}>
          {paragraph && <BivariateParagraph {...paragraph} />}
          <BivariateHeatmap
            {...squareProps}
            colors={colors}
            xCategories={xCategories}
            yCategories={yCategories}
            selectedBivariatePoint={selectedBivariatePoint}
          />
          {tableProps && (
            <BivariateTable
              xAxisTitle={`${xAxisResult.statIdentifier?.name ?? ""}`}
              yAxisTitle={`${yAxisResult.statIdentifier?.name ?? ""}`}
              data={bivariateTableData}
              {...tableProps}
            />
          )}
        </Stack>
      </Grid>
      {!showLocationDetail && (
        <Grid item xs={12} height={{ xs: "500px", lg: "auto", display: "flex" }} lg={6}>
          <BivariateMap
            {...mapProps}
            width={mapWidth}
            height={mapHeight}
            valueMap={valueMap}
            xAxisResult={xAxisResult}
            yAxisResult={yAxisResult}
            locationReference={locationReference}
            selectedBivariatePoint={selectedBivariatePoint}
            setSelectedBivariatePoint={setSelectedBivariatePoint}
          />
        </Grid>
      )}
    </Grid>
  );
};
