"use client";

import React, { ReactElement } from "react";
import { Box, Stack, Typography } from "@mui/material";

import {
  TopicDashboardDataGroup,
  TopicDashboardSubSectionData
} from "../util/elementHelpers/dashboard/types";
import { MhcAttributionFragment } from "graphqlApi/types";

import { TopicSectionDescription } from "../util/elementHelpers/ClimateChange/vulnerability/sectionDescriptionDictionary";
import {
  TopicSectionContent,
  TopicSectionContentDictionary
} from "../util/elementHelpers/Lead/risk_factors/sectionContentDictionary";

import { ContentCardSectionTitle } from "layout/card/ContentCardSectionTitle";
import { ContentCardSubSection } from "layout/card/ContentCardSubSection";
import {
  BivariateSection,
  BivariateSectionProps
} from "common/components/BivariateSection/BivariateSection";
import { DynamicLineChart } from "common/components/charts/DynamicLineChart";
import {
  IndicatorInvestigateChart,
  IndicatorInvestigateChartProps
} from "common/components/charts/Investigate/IndicatorInvestigateChart";
import { LineChartProps } from "common/components/charts/LineChart";
import { IndicatorTableContentWrapper } from "common/components/IndicatorTable/IndicatorTableContentWrapper";
import KpiGroup from "common/components/KpiGroup";
import { AttributionPopover } from "modules/ECDC/components/AttributionPopover";
import TopicDashboardMap from "./TopicDashboardMap";
import { TopicDataNotAvailableNote } from "./TopicDataNotAvailableNote";
import { TopicSectionAboutTheData } from "./TopicSectionAboutTheData";

type TopicDashboardAboutTheData = {
  section: TopicDashboardDataGroup;
  attributions?: MhcAttributionFragment[];
  uiLocation?: string;
};
export const topicDashboardAboutTheData = ({
  section,
  attributions = [],
  uiLocation
}: TopicDashboardAboutTheData) => {
  if (!section) return null;
  const {
    attributions: dataSources = attributions,
    aboutTheDataContent,
    showAttributionsInAboutTheData = true
  } = section;
  if (!attributions?.length && !aboutTheDataContent) return null;
  return (
    <TopicSectionAboutTheData
      uiLocation={uiLocation}
      dataSources={showAttributionsInAboutTheData ? dataSources ?? [] : []}
      aboutContent={aboutTheDataContent}
    />
  );
};

interface Props {
  currentTopicSlug?: string;
  description?: TopicSectionDescription;
  introContent?: ReactElement;
  locationName?: string;
  section: TopicDashboardSubSectionData;
  sectionContentDictionary?: TopicSectionContentDictionary;
  topicName?: string;
}

export const TopicDashboardData: React.FC<Props> = ({
  currentTopicSlug,
  description,
  introContent = null,
  locationName,
  section,
  sectionContentDictionary,
  topicName
}) => {
  const { id, title, kpiGroup, map, mapV2, indicatorTable, chartGroup, bivariateAnalysis } =
    section;
  if (!kpiGroup && !map && !mapV2 && !indicatorTable && !chartGroup && !bivariateAnalysis)
    return null;

  const subSectionProps = { sx: { mb: 4 } };

  let _introContent = introContent;
  if (!_introContent && sectionContentDictionary && sectionContentDictionary[id] !== undefined) {
    _introContent = (sectionContentDictionary[id] as TopicSectionContent)();
  }

  return (
    <Box component="article">
      {title && <ContentCardSectionTitle title={title} />}
      {_introContent}
      <Stack gap={6}>
        {kpiGroup && (
          <ContentCardSubSection {...subSectionProps} sx={{ mb: 0 }}>
            <KpiGroup {...kpiGroup} description={(description && description()) ?? null} />
            {topicDashboardAboutTheData({
              section: kpiGroup,
              uiLocation: `${topicName ?? ""} KPIs`
            })}
          </ContentCardSubSection>
        )}
        {(map || mapV2) && (
          <TopicDashboardMap
            map={map}
            mapV2={mapV2}
            subSectionProps={subSectionProps}
            description={description}
            topicName={topicName}
          />
        )}
        {indicatorTable && indicatorTable.dataAvailable && (
          <ContentCardSubSection {...subSectionProps} sx={{ mb: 0 }}>
            <IndicatorTableContentWrapper
              {...indicatorTable.props}
              currentTopicSlug={currentTopicSlug}
              uiLocation={topicName}
            />
            {topicDashboardAboutTheData({
              section: indicatorTable,
              uiLocation: `${topicName ?? ""} indicator table`.trim()
            })}
          </ContentCardSubSection>
        )}
        {indicatorTable && !indicatorTable.dataAvailable && (
          <ContentCardSubSection {...subSectionProps} sx={{ mb: 0 }}>
            <TopicDataNotAvailableNote sx={{ mt: -2 }} />
          </ContentCardSubSection>
        )}
        {chartGroup?.charts?.map(({ id, title, props, type, attributions = [] }, i) => (
          <ContentCardSubSection
            key={i}
            {...subSectionProps}
            sx={{ display: "flex", flexDirection: "column", gap: 4, mb: 0 }}
          >
            {title && (
              <ContentCardSectionTitle
                title={title}
                subtitle={locationName}
                utilityLink={
                  attributions && attributions.length ? (
                    <AttributionPopover
                      id={`${id}--about-the-data`}
                      attributions={attributions}
                      uiLocation={`${topicName ?? "Unknown topic"}: ${title}`}
                    />
                  ) : undefined
                }
              />
            )}
            {type === "indicator-investigation" && (
              <>
                <IndicatorInvestigateChart {...(props as IndicatorInvestigateChartProps)} />
                {topicDashboardAboutTheData({
                  section: chartGroup,
                  attributions:
                    (chartGroup?.charts || []).flatMap(({ attributions }) => attributions ?? []) ??
                    [],
                  uiLocation: `${topicName ?? ""} indicator investigation`.trim()
                })}
              </>
            )}
            {type === "line" && <DynamicLineChart {...(props as LineChartProps)} />}
          </ContentCardSubSection>
        ))}
        {bivariateAnalysis && (
          <ContentCardSubSection
            title={bivariateAnalysis.title}
            utilityLink={
              bivariateAnalysis?.attributions ? (
                <AttributionPopover
                  id={`${bivariateAnalysis.id}--about-the-data`}
                  attributions={bivariateAnalysis.attributions}
                  uiLocation={`${topicName ?? "Unknown topic"}: ${
                    bivariateAnalysis.title ?? "Bivariate Analysis"
                  }`}
                />
              ) : undefined
            }
            sx={{ mb: 0 }}
          >
            <Box mt={-3}>
              <Typography variant="h4">{locationName}</Typography>
            </Box>
            <Box py={4}>
              <BivariateSection {...(bivariateAnalysis.props as BivariateSectionProps)} />
            </Box>
            {topicDashboardAboutTheData({
              section: bivariateAnalysis,
              uiLocation: `${topicName ?? ""} bivariate analysis`.trim()
            })}
          </ContentCardSubSection>
        )}
      </Stack>
    </Box>
  );
};
