import { Box, BoxProps, Divider, Skeleton, Stack, StackProps, Typography } from "@mui/material";
import times from "lodash/times";

import { StatAttributionProps } from "./StatAttribution";

interface Props extends BoxProps {
  attributions?: number;
  controlWidth?: boolean;
  itemGap?: StackProps["gap"];
  showDivider?: boolean;
  showTitle?: boolean;
  titleFontWeight?: StatAttributionProps["titleFontWeight"];
}

export const StatAttributionListSkeleton: React.FC<Props> = ({
  attributions = 3,
  controlWidth = true,
  itemGap = 4,
  showDivider = true,
  showTitle = true,
  sx,
  titleFontWeight,
  ...props
}) => {
  return (
    <Box
      sx={{
        ...(controlWidth
          ? {
              maxWidth: {
                md: "500px",
                xs: "350px"
              }
            }
          : {}),
        ...sx
      }}
      {...props}
    >
      {showTitle && (
        <>
          <Typography variant="h4" mb={1} component="h5">
            Data Sources
          </Typography>
          {showDivider && <Divider sx={{ mb: 2 }} />}
        </>
      )}
      <Stack gap={itemGap}>
        {times(attributions).map((i) => (
          <Stack key={i} gap="4px">
            <Skeleton variant="text" width="150px" />
            <Skeleton variant="rectangular" width="200px" height="150px" />
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};
