import { Link, LinkProps } from "@mui/material";

export const ExternalLink: React.FC<LinkProps> = ({ href, ...props }) => (
  <Link
    className="link-with-underline"
    href={href}
    {...props}
    target="_blank"
    rel="noopener noreferrer"
  />
);
