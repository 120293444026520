export const buildColorArrayFromColorRange = (
  high: string,
  moderate: string,
  moderateToLow: string,
  low: string
): string[][] => {
  return [
    // eslint-disable-next-line prettier/prettier
    [moderate, moderateToLow, low, low],
    // eslint-disable-next-line prettier/prettier
    [moderate, moderateToLow, moderateToLow, low],
    // eslint-disable-next-line prettier/prettier
    [high, moderate, moderateToLow, low],
    // eslint-disable-next-line prettier/prettier
    [high, high, moderate, low]
  ];
};
