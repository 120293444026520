import { InvestigateMapProps } from "./types";
import { MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { getGeographyEnumFromReadableName } from "common/util/geographyHelpers";

import {
  InvestigateMapLoadStatIdentifierProps,
  investigateMapLoadStatIdentifiers
} from "../investigateMapLoadStatIdentifiers";

interface loadStatIdentifierProps {
  statId: string;
  statGranularity?: MhcTimeSeriesGranularityEnum;
  locationIds: InvestigateMapLoadStatIdentifierProps["locationIds"];
  foundIndex: number;
  selectedCustomOption?: string;
  loadingStatId?: string;
  setLoadingStatId: React.Dispatch<React.SetStateAction<string | undefined>>;
  expectedGranularity: InvestigateMapProps["expectedGranularity"];
  overrideStatIdLoader: InvestigateMapProps["overrideStatIdLoader"];
  investigations: InvestigateMapProps["investigations"];
  setInvestigations: InvestigateMapProps["setInvestigations"];
  setLoadingMap: React.Dispatch<React.SetStateAction<boolean>>;
  selectedGranularity: string;
}

export const loadStatIdentifier = async ({
  statId,
  statGranularity,
  locationIds,
  foundIndex,
  selectedCustomOption,
  loadingStatId,
  setLoadingStatId,
  expectedGranularity,
  overrideStatIdLoader,
  investigations,
  setInvestigations,
  setLoadingMap,
  selectedGranularity
}: loadStatIdentifierProps) => {
  if (loadingStatId !== undefined) {
    return;
  }
  setLoadingStatId(statId);
  const props: InvestigateMapLoadStatIdentifierProps = {
    statId,
    locationIds,
    optionId: selectedCustomOption,
    granularity: statGranularity || expectedGranularity
  };
  const { data, minMax } = await (async () => {
    if (overrideStatIdLoader !== undefined) {
      return overrideStatIdLoader(props);
    }
    return investigateMapLoadStatIdentifiers(props);
  })();
  const geoEnum = getGeographyEnumFromReadableName(selectedGranularity);
  const investigationMinMax = minMax && geoEnum ? minMax[geoEnum] : undefined;

  const currentInvestigation = investigations[foundIndex];
  if (currentInvestigation === undefined) {
    return investigations;
  }
  currentInvestigation.data = data;
  if (investigationMinMax && currentInvestigation.minMax && geoEnum) {
    currentInvestigation.minMax[geoEnum] = {
      maxValue: investigationMinMax?.maxValue ?? null,
      minValue: investigationMinMax?.minValue ?? null
    };
  }
  currentInvestigation.loaded = true;
  setInvestigations([
    ...investigations.slice(0, foundIndex),
    currentInvestigation,
    ...investigations.slice(foundIndex + 1, investigations.length)
  ]);
  setLoadingStatId(undefined);
  setLoadingMap(false);
};
