"use client";

import { create } from "zustand";

type LocationIds = {
  currentLocationId?: string;
  nextLocationId?: string;
};

type State = LocationIds & {
  isNavigating: boolean;
  pageTimedOut: boolean;
};

type Actions = {
  setIsNavigating: (isNavigating: boolean) => void;
  setNavigatingState: (props: {
    isNavigating: boolean;
    locationIds?: LocationIds;
    pageTimedOut?: boolean;
  }) => void;
  setLocationIds: (locationIds: LocationIds) => void;
};

export const useNavigationStore = create<State & Actions>((set) => ({
  isNavigating: false,
  pageTimedOut: false,
  currentLocationId: "",
  nextLocationId: "",
  setLocationIds: (locationIds: LocationIds) => {
    set((state) => ({ ...state, ...locationIds }));
  },
  setIsNavigating: (isNavigating: boolean) =>
    set((state) => ({ ...state, isNavigating: isNavigating ?? false })),
  setNavigatingState: ({ isNavigating, locationIds = {}, pageTimedOut }) => {
    const { currentLocationId: _currentLocationId = "", nextLocationId: _nextLocationId = "" } =
      locationIds;
    set((state) => {
      let nextLocationId = _nextLocationId || state.nextLocationId;
      const currentLocationId = _currentLocationId || state.currentLocationId;
      if (!isNavigating) {
        nextLocationId = "";
      }
      return {
        ...state,
        isNavigating: isNavigating,
        currentLocationId,
        nextLocationId,
        pageTimedOut: pageTimedOut ?? false
      };
    });
  }
}));
