import { InvestigateMapProps } from "./types";
import { MhcGeographyEnum } from "graphqlApi/types";

import { InvestigateMapLoadStatIdentifierProps } from "../investigateMapLoadStatIdentifiers";

export const processLocationIdReference = (
  locationIdReference: InvestigateMapProps["locationIdReference"]
) => {
  if (locationIdReference === undefined) {
    return [];
  }
  const keys = Object.keys(locationIdReference);
  const locationIds: InvestigateMapLoadStatIdentifierProps["locationIds"] = [];
  keys.forEach((key) => {
    const reference = locationIdReference[key as MhcGeographyEnum];
    if (reference) {
      locationIds.push({
        geography: key as MhcGeographyEnum,
        ids: reference.locationIds
      });
    }
  });
  return locationIds;
};
