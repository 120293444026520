import React from "react";
import { StaticImport } from "next/dist/shared/lib/get-img-props";
import Image, { ImageProps } from "next/legacy/image";
import { Box, Card, CardContent, CardMedia, CardProps, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { defaultCardPadding } from "layout/configuration";

export interface FeatureCardProps extends Omit<CardProps, "variant"> {
  src: string | StaticImport;
  alt?: string;
  vertical?: boolean;
  horizontal?: boolean;
  imageHeight?: number;
  contentPadding?: number;
  variant?: "small" | "large";
  imageObjectPosition?: ImageProps["objectPosition"];
  href?: string;
}

export const FeatureCard: React.FC<FeatureCardProps> = ({
  src,
  alt,
  children,
  horizontal = false,
  vertical = false,
  imageHeight = 170,
  variant = "large",
  contentPadding = defaultCardPadding,
  imageObjectPosition,
  ...cardProps
}) => {
  const theme = useTheme();
  const supportsHorizontal = useMediaQuery(theme.breakpoints.up("sm"));
  const isHorizontal = horizontal || (vertical ? false : supportsHorizontal);
  let mediaWidth = "auto";
  isHorizontal && (mediaWidth = "40%");
  variant === "small" && (mediaWidth = "25%");

  return (
    <Card
      elevation={0}
      {...cardProps}
      sx={{
        borderRadius: "5px",
        display: "flex",
        flexDirection: isHorizontal ? "row" : "column",
        "&:hover": {
          backgroundColor: "component.hoverBackground"
        },
        ...cardProps.sx
      }}
    >
      {src && (
        <CardMedia
          sx={{
            maxWidth: mediaWidth,
            flex: `1 1 ${mediaWidth}`,
            height: isHorizontal ? undefined : imageHeight,
            position: "relative",
            flexShrink: 0
          }}
        >
          <Box sx={{ position: "absolute", inset: 0 }}>
            <Image
              src={src}
              alt={`${alt ?? ""}`}
              objectFit="cover"
              objectPosition={imageObjectPosition}
              layout="fill"
            />
          </Box>
        </CardMedia>
      )}
      <CardContent
        sx={(theme) => ({
          p: contentPadding,
          backgroundColor: theme.palette.brand.light,
          flex: "auto",
          "&:hover": {
            backgroundColor: "component.hoverBackground"
          }
        })}
      >
        {children}
      </CardContent>
    </Card>
  );
};
