import { InvestigateMapProps } from "./types";

interface getValueForFeatureIdProps {
  featureId: string;
  customOptions: InvestigateMapProps["customOptions"];
  hideGeographyGranularitySelector: InvestigateMapProps["hideGeographyGranularitySelector"];
  investigations: InvestigateMapProps["investigations"];
  selectedCustomOption?: string;
  investigateType?: string;
}

export const getValueForFeatureId = ({
  featureId,
  customOptions,
  hideGeographyGranularitySelector,
  investigations,
  selectedCustomOption,
  investigateType
}: getValueForFeatureIdProps) => {
  const selectedInvestigation = (() => {
    if (customOptions && customOptions.length > 0 && hideGeographyGranularitySelector === true) {
      const r = investigations.filter(
        (investigationOption) =>
          investigationOption.linkedOption === selectedCustomOption &&
          investigationOption.title === investigateType
      );
      return r.length > 0 ? r[0] : undefined;
    }
    const r = investigations.filter(
      (investigationOption) => investigationOption.title === investigateType
    );
    return r.length > 0 ? r[0] : undefined;
  })();
  if (selectedInvestigation === undefined) {
    return undefined;
  }
  if (selectedInvestigation.loaded === false) {
    return;
  }
  const value = selectedInvestigation?.data[featureId] ?? undefined;
  return value;
};
