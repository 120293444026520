"use client";

import { Grid } from "@mui/material";

import { DataStory } from "common/content/dataStories";

import DataStoryCard from "./DataStoryCard";

interface Props {
  stories: DataStory[];
  showDescription?: boolean;
}

const DataStoryGrid: React.FC<Props> = ({ stories, showDescription = true }) => {
  return (
    <>
      {stories.map(({ title, ...props }) => (
        <Grid key={title} item columns={12} xs={12} md={12} sx={{ mx: "auto" }}>
          <DataStoryCard title={title} showDescription={showDescription} {...props} />
        </Grid>
      ))}
    </>
  );
};

export default DataStoryGrid;
