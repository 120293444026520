import { DataTableBaseRow, TableColumn } from "../DataTable/types";
import { MhcGeographyEnum } from "graphqlApi/types";

import { getReadableGeographyName } from "common/util/geographyHelpers";

import { DataTable } from "../DataTable";

export interface BivariateTableRow extends DataTableBaseRow {
  locationName: string;
  xAxisValue: string | null;
  yAxisValue: string | null;
  evaluationScore?: string | null;
}

export interface BivariateTableProps {
  geographyType: MhcGeographyEnum;
  xAxisTitle: string;
  yAxisTitle: string;
  evaluationTitle?: string;
  data: BivariateTableRow[];
  maxHeight?: string | number;
}

export const BivariateTable: React.FC<BivariateTableProps> = ({
  geographyType,
  xAxisTitle,
  yAxisTitle,
  evaluationTitle,
  data,
  maxHeight = 450
}) => {
  const columns: TableColumn<BivariateTableRow>[] = (() => {
    const baseColumns: TableColumn<BivariateTableRow>[] = [
      {
        id: "location",
        display: `${getReadableGeographyName(geographyType)}`,
        render: (row) => <strong>{row.locationName}</strong>
      },
      {
        id: "xAxisValue",
        display: `${xAxisTitle}`,
        align: "center",
        render: (row) => {
          if (row.xAxisValue === null) {
            return <>NA</>;
          }
          return <>{row.xAxisValue}</>;
        }
      },
      {
        id: "yAxisValue",
        display: `${yAxisTitle}`,
        align: "center",
        render: (row) => {
          if (row.yAxisValue === null) {
            return <>NA</>;
          }
          return <>{row.yAxisValue}</>;
        }
      }
    ];
    if (evaluationTitle !== undefined) {
      baseColumns.push({
        id: "evaluation",
        display: `${evaluationTitle}`,
        align: "center",
        render: (row) => {
          if (row.evaluationScore === null) {
            return <>NA</>;
          }
          return <>{row.evaluationScore}</>;
        }
      });
    }
    return baseColumns;
  })();

  return <DataTable maxHeight={maxHeight} data={data} columns={columns} />;
};
