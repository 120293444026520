import uniqBy from "lodash/uniqBy";

import { StatAttributions } from "../util/elementHelpers/dashboard/types";

import { StatAttributionList } from "modules/ECDC/components/StatAttributionList";

interface Props {
  attributions: StatAttributions;
}

export const TopicAttributionList: React.FC<Props> = ({ attributions }) => {
  return (
    <StatAttributionList
      /* If we use source, some datasets might get unintentionally hidden */
      attributions={uniqBy(attributions, "id")}
      showDivider={false}
      showTitle={false}
      controlWidth={false}
    />
  );
};
