import { ReactNode } from "react";
import { Box, CircularProgress } from "@mui/material";

import { SIDEBAR_WIDTH } from "layout/DashboardContent/DashboardContentContainer";

export const CardLoadingIndicator: React.FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgb(255,255,255,.95)",
        zIndex: 1001
      }}
    >
      <Box
        sx={{
          position: "fixed",
          top: "50vh",
          left: `calc(50% + ${SIDEBAR_WIDTH / 2}px)`,
          transform: "translateX(-50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2
        }}
      >
        <CircularProgress size={60} sx={{}} />
        {children && <Box>{children}</Box>}
      </Box>
    </Box>
  );
};
