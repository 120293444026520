// TODO: Refactor to not use `styled`
"use client";

import { styled } from "@mui/material/styles";

export const UnstyledList = styled("ul")(({ theme: { spacing } }) => ({
  listStyle: "none",
  paddingLeft: 0,
  margin: 0,
  "> li": {
    paddingLeft: 0,
    marginBottom: spacing(1)
  }
}));
