import SouthRounded from "@mui/icons-material/SouthRounded";
import { Box, Typography } from "@mui/material";
import compact from "lodash/compact";

import { SectionedSliderProps } from "./types/types";

import { sliderEmptyPercentage, sliderFillPercentage } from "./util/percentageCalculator";

import SliderLabels from "./SliderLabels";
import SliderSections from "./SliderSections";
import { SliderWrapper } from "./SliderWrapper";

export const SectionedSlider: React.FC<SectionedSliderProps> = ({
  barHeight = 30,
  centerSubtitle,
  colorSet,
  fillDirection,
  leftLabel,
  leftSubtitle,
  leftValue,
  rightLabel,
  rightSubtitle,
  rightValue,
  sectionColors,
  selectedFormattedValue,
  selectedValue,
  sliderValueTitle,
  width = "100%"
}) => {
  const fillPercentage = selectedValue
    ? sliderFillPercentage(leftValue, selectedValue, rightValue, fillDirection)
    : undefined;
  const emptyPercentage = selectedValue
    ? sliderEmptyPercentage(leftValue, selectedValue, rightValue, fillDirection)
    : undefined;

  return (
    <SliderWrapper
      leftSubtitle={leftSubtitle}
      rightSubtitle={rightSubtitle}
      centerSubtitle={centerSubtitle}
      width={width}
    >
      {selectedValue && fillPercentage && emptyPercentage && (
        <Box
          sx={{ width: "100%", display: "flex", flexDirection: "row" }}
          className="sectioned-slider"
        >
          <Box
            sx={{
              height: `${barHeight}px`,
              width:
                fillDirection === "left_to_right" ? `${fillPercentage}%` : `${emptyPercentage}%`
            }}
          ></Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "min-content",
              mb: "-25px",
              alignItems: "center"
            }}
          >
            {sliderValueTitle && (
              <Typography
                sx={{
                  my: "auto",
                  textAlign: "center",
                  width: "150%",
                  maxWidth: "20ch",
                  lineHeight: 1.3,
                  mb: 1
                }}
                variant="body2"
                fontWeight={600}
              >
                {sliderValueTitle}
              </Typography>
            )}
            <Box
              sx={({ palette }) => ({
                height: `${barHeight}px`,
                my: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "min-content",
                py: 2.5,
                px: 3,
                border: `3px solid ${palette.grey[500] ?? ""}`,
                borderRadius: "34px",
                boxSizing: "border-box"
              })}
            >
              <Typography
                sx={{
                  lineHeight: `${barHeight}px`,
                  my: "auto",
                  textAlign: "center",
                  verticalAlign: "center"
                }}
                variant="h3"
                fontWeight={700}
                component="p"
              >
                {selectedFormattedValue || selectedValue}
              </Typography>
            </Box>
            <SouthRounded
              sx={({ palette }) => ({ color: palette.grey[500], mx: "auto", mt: "-5px" })}
              fontSize="large"
            />
          </Box>
          <Box
            sx={{
              height: `${barHeight}px`,
              width:
                fillDirection === "left_to_right" ? `${emptyPercentage}%` : `${fillPercentage}%`
            }}
          ></Box>
        </Box>
      )}
      <SliderLabels labels={compact([leftLabel, rightLabel])} />
      <SliderSections sectionColors={sectionColors} colorSet={colorSet} barHeight={barHeight} />
    </SliderWrapper>
  );
};
