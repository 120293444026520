import { ReactNode } from "react";
import { Grid, Typography } from "@mui/material";

import ContentCardHeaderFallback from "./ContentCardHeaderFallback";

interface Props {
  variant?: "primary" | "secondary";
  title?: string;
  subtitle?: string;
  supertitle?: string;
  children?: ReactNode;
  skeleton?: boolean;
}

export const ContentCardHeader: React.FC<Props> = ({
  title,
  subtitle,
  supertitle,
  variant = "secondary",
  skeleton,
  children
}) => {
  const isPrimary = variant === "primary";

  if (skeleton) return <ContentCardHeaderFallback />;

  return (
    <Grid container spacing={{ xs: 2, md: 4 }} alignItems="flex-end">
      <Grid item md={children ? 8 : 12}>
        {supertitle && (
          <Typography variant="h5" component={"p"} sx={{ mb: "8px" }}>
            {supertitle}
          </Typography>
        )}
        <Typography
          variant={isPrimary ? "h1" : "h2"}
          component={isPrimary ? "h1" : "h2"}
          fontWeight={isPrimary ? 300 : 400}
          sx={isPrimary ? { mb: "0px" } : { mb: "10px" }}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="h4" component={"p"}>
            {subtitle}
          </Typography>
        )}
      </Grid>
      {children && (
        <Grid item md={4} sx={{ width: "100%" }}>
          {children}
        </Grid>
      )}
    </Grid>
  );
};
