import { ChangeEvent } from "react";
import { Grid } from "@mui/material";

import { InvestigateDropdown } from "../charts/Investigate/InvestigateDropdown";
import { InvestigateMapProps } from "./InvestigateMap";

interface InvestigateMapCustomOptionsProps {
  displayDirection: InvestigateMapProps["displayDirection"];
  markerSectionTitle: InvestigateMapProps["markerSectionTitle"];
  markerSectionSubtitle: InvestigateMapProps["markerSectionSubtitle"];
  customOptionTitle: InvestigateMapProps["customOptionTitle"];
  customOptions: InvestigateMapProps["customOptions"];
  selectedCustomOption?: string;
  handleCustomOptionOnChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const InvestigateMapCustomOptions = ({
  displayDirection,
  markerSectionTitle,
  markerSectionSubtitle,
  customOptionTitle,
  customOptions,
  selectedCustomOption,
  handleCustomOptionOnChange
}: InvestigateMapCustomOptionsProps) => {
  return (
    <>
      {customOptions && customOptions.length > 0 && selectedCustomOption && customOptionTitle && (
        <Grid
          item
          xs={12}
          md={displayDirection === "column" ? 12 : 5}
          sx={{
            height: "min-content",
            mt: markerSectionTitle || markerSectionSubtitle ? 0 : "auto",
            mb: displayDirection === "column" ? 3 : undefined
          }}
        >
          <InvestigateDropdown
            value={selectedCustomOption}
            title={customOptionTitle}
            onChange={handleCustomOptionOnChange}
            options={customOptions.map((option) => ({
              title: option,
              value: option
            }))}
            type="Map"
          />
        </Grid>
      )}
    </>
  );
};
