import { Breakpoint } from "@mui/material";

import { theme } from "theme/theme";

type CssMediaQuery = {
  breakpoint?: Breakpoint;
  maxOrMin?: "min" | "max";
};

export const cssMediaQuery = (
  { breakpoint = "md", maxOrMin = "min" }: CssMediaQuery = { breakpoint: "md", maxOrMin: "min" }
) => `@media screen and (${maxOrMin}-width: ${theme.breakpoints.values[breakpoint]}px)`;
