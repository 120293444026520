import { Stack, Typography } from "@mui/material";

import { BivariateHeatmapProps, NumberLabel } from "./BivariateHeatmap";

type BivariateAxisLabelProps = {
  title?: BivariateHeatmapProps["yAxisMaxTitle"] | BivariateHeatmapProps["yAxisMinTitle"];
  value?: string | number;
};
const BivariateAxisLabel: React.FC<BivariateAxisLabelProps> = ({ title, value }) => (
  <Stack alignItems="flex-end" gap="2px">
    {value && <NumberLabel content={value} />}
    <Typography variant="caption" textAlign="right" component="span" lineHeight={1}>
      {title}
    </Typography>
  </Stack>
);

export default BivariateAxisLabel;
