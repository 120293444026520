import { AdditionalInfoProps } from "./types";

import ResourcesAndAttributions from "modules/Topics/components/TopicAdditionalInfo/ResourcesAndAttributions";
import AdditionalInfoPrivacyContent from "../PrivacyContent";

const YouthUseOfPrescriptionPainMedsAdditionalInfo: React.FC<AdditionalInfoProps> = ({
  attributions,
  resources
}) => {
  return (
    <>
      <ResourcesAndAttributions resources={resources} attributions={attributions} />
      <AdditionalInfoPrivacyContent />
    </>
  );
};

export default YouthUseOfPrescriptionPainMedsAdditionalInfo;
