import { Skeleton } from "@mui/material";

import { DEFAULT_SKELETON_ANIMATION, DEFAULT_SKELETON_STYLE } from "layout/configuration";

const IndicatorTableModalChartFallback = () => (
  <Skeleton
    variant="rectangular"
    width="100%"
    height={400}
    animation={DEFAULT_SKELETON_ANIMATION}
    sx={DEFAULT_SKELETON_STYLE}
  />
);

export default IndicatorTableModalChartFallback;
