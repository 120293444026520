import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import { AlertProps } from "@mui/material";

import { MhcAlert } from "./MhcAlert";

interface Props {
  note?: string;
  sx?: AlertProps["sx"];
}

export const DataNotAvailableNote: React.FC<Props> = ({
  note = "Due to insufficient data, community-specific data is unable to be shown in this section.",
  sx = {}
}) => (
  <MhcAlert
    severity="warning"
    icon={<VisibilityOffOutlined fontSize="small" color="inherit" sx={{ color: "#000" }} />}
    sx={{ mb: 4, ...sx }}
  >
    {note}
  </MhcAlert>
);
