import { ChangeEvent, useCallback, useMemo } from "react";
import Grid from "@mui/material/Grid";
import { getMhcStatIdentifier } from "graphqlApi/legacy/mhcClient";

import { MhcStatIdentifier } from "graphqlApi/types";

import { loadStatIdentifier } from "./util/loadStatIdentifier";
import { processLocationIdReference } from "./util/processLocationIdReference";

import { InvestigateDropdown } from "../charts/Investigate/InvestigateDropdown";
import { InvestigateRange, RangeOptions } from "./styles/investigateMapStyles";
import { InvestigateMapProps } from "./InvestigateMap";

interface InvestigateMapInvestigationsProps {
  CustomDropdown: InvestigateMapProps["CustomDropdown"];
  dataToInvestigateTitle: InvestigateMapProps["dataToInvestigateTitle"];
  displayDirection: InvestigateMapProps["displayDirection"];
  customOptions: InvestigateMapProps["customOptions"];
  sortInvestigations: InvestigateMapProps["sortInvestigations"];
  investigations: InvestigateMapProps["investigations"];
  selectedCustomOption: string | undefined;
  setInvestigateType: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSelectedRangeForLegend: React.Dispatch<React.SetStateAction<InvestigateRange[]>>;
  setLoadingMap: React.Dispatch<React.SetStateAction<boolean>>;
  loadingMap: boolean;
  statIdentifierReference: InvestigateMapProps["statIdentifierReference"];
  setSelectedSi: InvestigateMapProps["setSelectedSi"];
  expectedGranularity: InvestigateMapProps["expectedGranularity"];
  overrideStatIdLoader: InvestigateMapProps["overrideStatIdLoader"];
  setInvestigations: InvestigateMapProps["setInvestigations"];
  colorPalette: InvestigateMapProps["colorPalette"];
  defaultInvestigation: InvestigateMapProps["defaultInvestigation"];
  investigateType?: string;
  selectedGranularity: string;
  loadingStatId?: string;
  setLoadingStatId: React.Dispatch<React.SetStateAction<string | undefined>>;
  investigationsOrder?: InvestigateMapProps["investigationsOrder"];
  locationIdReference: InvestigateMapProps["locationIdReference"];
}

export const InvestigateMapInvestigations = ({
  CustomDropdown,
  dataToInvestigateTitle,
  displayDirection,
  customOptions,
  sortInvestigations,
  investigations,
  selectedCustomOption,
  setInvestigateType,
  setSelectedRangeForLegend,
  setLoadingMap,
  loadingMap,
  statIdentifierReference,
  setSelectedSi,
  expectedGranularity,
  overrideStatIdLoader,
  setInvestigations,
  colorPalette,
  defaultInvestigation,
  investigateType,
  selectedGranularity,
  loadingStatId,
  setLoadingStatId,
  investigationsOrder,
  locationIdReference
}: InvestigateMapInvestigationsProps) => {
  const handleStatIdentifierChange = useCallback(
    async (id: string) => {
      let statIdentifier = statIdentifierReference[id];
      if (statIdentifier === undefined) {
        const fetchedSi = await getMhcStatIdentifier({ statId: id });
        statIdentifier = fetchedSi as MhcStatIdentifier;
      }
      setSelectedSi(statIdentifier, "HANDLE SI CHANGE");
    },
    [setSelectedSi, statIdentifierReference]
  );

  const handleInvestigationTypeOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      let selectedInvestigation;
      let foundIndex;
      const statId = event.target.value;
      if (typeof statId === "string") {
        selectedInvestigation = investigations.find(({ statId: id }) => statId === id);
        selectedInvestigation && (foundIndex = investigations.indexOf(selectedInvestigation));
      }
      setInvestigateType(selectedInvestigation?.title ?? "Zip Code");
      setSelectedRangeForLegend(
        RangeOptions[selectedInvestigation?.selectedRangeForLegend ?? "upto_hundred"](colorPalette)
      );
      if (
        selectedInvestigation?.loaded === false &&
        selectedInvestigation !== undefined &&
        foundIndex !== undefined &&
        loadingMap === false
      ) {
        setLoadingMap(true);
        void loadStatIdentifier({
          statId: selectedInvestigation.statId,
          statGranularity: selectedInvestigation.granularity,
          locationIds: processLocationIdReference(locationIdReference),
          foundIndex,
          selectedCustomOption,
          loadingStatId: loadingStatId,
          setLoadingStatId: setLoadingStatId,
          expectedGranularity,
          overrideStatIdLoader,
          investigations,
          setInvestigations,
          setLoadingMap,
          selectedGranularity
        });
      }
      void handleStatIdentifierChange(statId || event.target.value);
    },
    [
      setInvestigateType,
      setSelectedRangeForLegend,
      colorPalette,
      loadingMap,
      handleStatIdentifierChange,
      investigations,
      setLoadingMap,
      locationIdReference,
      selectedCustomOption,
      loadingStatId,
      setLoadingStatId,
      expectedGranularity,
      overrideStatIdLoader,
      setInvestigations,
      selectedGranularity
    ]
  );

  const availableInvestigations = useMemo(() => {
    const filtered =
      customOptions !== undefined && customOptions.length > 0
        ? investigations.filter(
            (investigation) => investigation.linkedOption === selectedCustomOption
          )
        : investigations;
    let mapped = filtered;
    const index = investigationsOrder?.map((id) => {
      const idx = investigations.findIndex((inv) => id === inv.statId);
      return idx;
    });
    if (investigationsOrder) {
      const ordered = index?.map(
        (index) => investigations[index]
      ) as InvestigateMapProps["investigations"];
      if (ordered) {
        return ordered.map((option) => ({
          title: option.title,
          value: option.value
        }));
      }
    }
    if (sortInvestigations) {
      mapped = mapped.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      });
    }
    return mapped.map((option) => ({
      title: option.title,
      value: option.value
    }));
  }, [
    customOptions,
    investigations,
    investigationsOrder,
    sortInvestigations,
    selectedCustomOption
  ]);

  return (
    <Grid
      item
      xs={12}
      md={displayDirection === "column" ? 12 : 6}
      sx={{
        ml: displayDirection === "row" ? "auto" : undefined,
        mb: {
          xs: displayDirection === "column" ? "20px" : undefined,
          md: displayDirection === "column" ? "auto" : undefined
        },
        height: "min-content"
      }}
    >
      {CustomDropdown ? (
        <CustomDropdown onChange={handleInvestigationTypeOnChange} />
      ) : (
        <InvestigateDropdown
          value={investigateType ?? "Select data to investigate"}
          title={dataToInvestigateTitle}
          onChange={handleInvestigationTypeOnChange}
          defaultValue={defaultInvestigation}
          options={availableInvestigations}
          type="Map"
        />
      )}
    </Grid>
  );
};
