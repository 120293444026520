"use client";

import { useState } from "react";
import { Box, Divider, Link, Typography } from "@mui/material";

export const WhatIsAgeAdjustedAndWhyItMattersPopover = () => {
  const [readMore, setReadMore] = useState<boolean>(false);

  return (
    <Box
      padding="20px"
      width={{
        md: "500px",
        xs: "350px"
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography variant="h5" color="light.primary" sx={{ mr: "auto" }}>
          Age-Adjusted Rate
        </Typography>
        {false && (
          <Link href="#" sx={{ ml: "auto" }}>
            <Typography component="span" variant="body2" color="component.links">
              Go to FAQs
            </Typography>
          </Link>
        )}
      </Box>
      <Divider sx={{ mt: "10px" }} />
      <Box
        sx={{
          p: 0,
          pt: "10px",
          m: 0,
          overflowY: readMore ? "scroll" : undefined,
          maxHeight: "285px"
        }}
      >
        <Typography variant="body1" color="light.primary">
          Age adjusting rates is a way to make fairer comparisons between groups with different age
          distributions. For example, a county having a higher percentage of elderly people may have
          a higher rate of death or hospitalization than a county with a younger population, merely
          because the elderly are more likely to die or be hospitalized. (The same distortion can
          happen when comparing races, genders, or time periods.) Age adjustment can make the
          different groups more comparable.
        </Typography>
        {readMore && (
          <>
            <br />
            <Typography variant="body1" color="light.primary">
              A &quot;standard&quot; population distribution is used to adjust death and
              hospitalization rates. The age-adjusted rates are rates that would have existed if the
              population under study had the same age distribution as the &quot;standard&quot;
              population. Therefore, they are summary measures adjusted for differences in age
              distributions.
            </Typography>
            <br />
            <Typography variant="body1" color="light.primary">
              The National Center for Health Statistics recommends that the U.S. 2000 standard
              population be used when calculating age-adjusted rates.
            </Typography>
          </>
        )}
      </Box>
      {!readMore && (
        <Typography
          onClick={() => setReadMore(true)}
          component="p"
          variant="body2"
          sx={{ background: "none", textDecoration: "underline", mt: "10px", cursor: "pointer" }}
          color="component.links"
        >
          Read more
        </Typography>
      )}
    </Box>
  );
};
