import { ReactElement, ReactNode } from "react";
import { Box, BoxProps } from "@mui/material";

import { ContentCardSectionTitle } from "layout/card/ContentCardSectionTitle";

export interface ContentCardSubSectionProps extends Pick<BoxProps, "sx"> {
  title?: string;
  className?: string;
  component?: BoxProps["component"];
  utilityLink?: ReactElement | null;
  dates?: ReactElement;
  children?: ReactNode;
}

export const ContentCardSubSection: React.FC<ContentCardSubSectionProps> = ({
  children,
  sx,
  title,
  utilityLink,
  dates,
  className = "",
  component = "article"
}) => {
  return (
    <Box component={component} className={`sub-section ${className}`.trim()} sx={sx}>
      {title && <ContentCardSectionTitle title={title} utilityLink={utilityLink} dates={dates} />}
      {children}
    </Box>
  );
};
