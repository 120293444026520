"use client";

import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Checkbox, FormControlLabel, FormGroup, Grid, InputLabel, Typography } from "@mui/material";

import { MhcFeatureCollection } from "graphqlApi/types";

import { InvestigateMapAvailableGeographies, InvestigateMapProps } from "./InvestigateMap";

interface InvestigateMapMarkersProps {
  getSelectedGeoJson: (
    availableGeographies: InvestigateMapAvailableGeographies[],
    mapType: string
  ) => MhcFeatureCollection | undefined;
  setGeoJSON: React.Dispatch<React.SetStateAction<MhcFeatureCollection | undefined>>;
  setSelectedId: InvestigateMapProps["setSelectedId"];
  markerSectionTitle: InvestigateMapProps["markerSectionTitle"];
  markerSectionSubtitle: InvestigateMapProps["markerSectionSubtitle"];
  markerTitle: InvestigateMapProps["markerTitle"];
  markers: InvestigateMapProps["markers"];
  availableGeographies: InvestigateMapProps["availableGeographies"];
  selectedGranularity: string;
}

export const InvestigateMapMarkers = ({
  markerSectionTitle,
  markerSectionSubtitle,
  markerTitle,
  markers,
  getSelectedGeoJson,
  availableGeographies,
  setGeoJSON,
  setSelectedId,
  selectedGranularity
}: InvestigateMapMarkersProps) => {
  const [selectedMarkers, setSelectedMarkers] = useState<string[]>([]);

  const onCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const newIncludedMarkers =
        checked === false
          ? selectedMarkers.filter((selected) => selected !== event.target.value)
          : [...selectedMarkers, event.target.value];
      const includedMarkers =
        markers === undefined
          ? []
          : markers.filter((marker) => newIncludedMarkers.includes(marker.title));
      let geoJson = getSelectedGeoJson(availableGeographies, selectedGranularity);
      if (geoJson !== undefined) {
        includedMarkers.forEach((marker) => {
          marker.geoJSON.features.forEach((feature) => {
            if (feature) {
              geoJson = {
                ...geoJson,
                type: "FeatureCollection",
                features: [...(geoJson?.features ?? []), feature]
              };
            }
          });
        });
      }
      setGeoJSON(geoJson);
      setSelectedMarkers(newIncludedMarkers);
    },
    [
      availableGeographies,
      getSelectedGeoJson,
      selectedGranularity,
      markers,
      selectedMarkers,
      setGeoJSON
    ]
  );

  useEffect(() => {
    setSelectedId(undefined);
  }, [selectedMarkers, setSelectedId]);

  return (
    <Grid item sx={{ mt: "auto", mb: 3, height: "min-content" }} xs={12}>
      {markerSectionTitle && (
        <Typography variant="body1" fontWeight={700}>
          {markerSectionTitle}
        </Typography>
      )}
      {markerSectionSubtitle && <Typography variant="body1">{markerSectionSubtitle}</Typography>}
      {markerTitle && <InputLabel sx={{ mt: "20px" }}>{markerTitle}</InputLabel>}
      {markers && (
        <FormGroup>
          {markers.map((marker) => {
            return (
              <FormControlLabel
                key={marker.title}
                control={
                  <Checkbox
                    sx={{
                      color: marker.color,
                      "&.Mui-checked": {
                        color: marker.color
                      }
                    }}
                    onChange={onCheckboxChange}
                    value={marker.title}
                  />
                }
                label={<Typography variant="body2">{marker.title}</Typography>}
              />
            );
          })}
        </FormGroup>
      )}
    </Grid>
  );
};
