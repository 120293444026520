import React from "react";
import { Box, Typography } from "@mui/material";

import { privacyStatementText } from "modules/Topics/constants";

import { ContentCardSectionTitle } from "layout/card/ContentCardSectionTitle";

export type AdditionalInfoPrivacyContentProps = {
  hasMap?: boolean;
};

const AdditionalInfoPrivacyContent: React.FC<AdditionalInfoPrivacyContentProps> = () => {
  return (
    <Box>
      <ContentCardSectionTitle title="Privacy" />
      <Typography sx={{ maxWidth: "90ch" }}>{privacyStatementText()}</Typography>
    </Box>
  );
};

export default AdditionalInfoPrivacyContent;
