// TODO: Refactor to not use `styled`
"use client";

import { forwardRef } from "react";
import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const PillButtonBase = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: 1000,
  fontWeight: theme.typography.fontWeightRegular,
  textDecoration: "none",
  "&.MuiButton-root": {
    color: "#fff"
  },
  "&.Mui-disabled": {
    border: "1px solid var(--grey-700, #616161)",
    color: "rgb(97, 97, 97)",
    cursor: "default"
  }
}));

const FPillButton = forwardRef<HTMLButtonElement | HTMLLinkElement, ButtonProps>((props, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <PillButtonBase variant="contained" color="brand" ref={ref as any} {...props} />;
});

FPillButton.displayName = "PillButton";

export const PillButton = FPillButton;
