import React from "react";
import {
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  TypographyProps
} from "@mui/material";

import { AdditionalInfoProps } from "./types";

import { ContentCardSectionTitle } from "layout/card/ContentCardSectionTitle";
import { ContentCardSubSection } from "layout/card/ContentCardSubSection";
import { ContentCardTextBlock } from "layout/card/ContentCardTextBlock";
import ResourcesAndAttributions from "modules/Topics/components/TopicAdditionalInfo/ResourcesAndAttributions";
import AdditionalInfoPrivacyContent from "../PrivacyContent";

const MentalHealthSubstanceUseAdditionalInfo: React.FC<AdditionalInfoProps> = ({
  attributions,
  resources
}) => {
  const footnotes: React.ReactNode[] = [
    <>
      <Typography variant="caption">
        NIDA. &ldquo;Part 1: The Connection Between Substance Use Disorders and Mental
        Illness.&ldquo; National Institute on Drug Abuse, 27 Sep. 2022,{" "}
        <Link href="https://nida.nih.gov/publications/research-reports/common-comorbidities-substance-use-disorders/part-1-connection-between-substance-use-disorders-mental-illness">
          https://nida.nih.gov/publications/research-reports/common-comorbidities-substance-use-disorders/part-1-connection-between-substance-use-disorders-mental-illness
        </Link>{" "}
        Accessed 14 May 2024.
      </Typography>
    </>,
    <>
      <Typography variant="caption">
        Czeisler MÉ , Lane RI, Petrosky E, et al. Mental Health, Substance Use, and Suicidal
        Ideation During the COVID-19 Pandemic — United States, June 24-30, 2020. MMWR Morb Mortal
        Wkly Rep 2020;69:1049-1057. DOI:{" "}
        <Link href="http://dx.doi.org/10.15585/mmwr.mm6932a1">
          http://dx.doi.org/10.15585/mmwr.mm6932a1
        </Link>
        .
        <Link href="https://www.cdc.gov/mmwr/volumes/69/wr/mm6932a1.htm">
          https://www.cdc.gov/mmwr/volumes/69/wr/mm6932a1.htm
        </Link>{" "}
        Accessed 14 May 2024.
      </Typography>
    </>,
    <>
      <Typography variant="caption">
        New CDC data illuminate youth mental health threats during the COVID-19 pandemic. CDC.
        Published March 31, 2022.
        <Link href="https://www.cdc.gov/media/releases/2022/p0331-youth-mental-health-covid-19.html">
          https://www.cdc.gov/media/releases/2022/p0331-youth-mental-health-covid-19.html
        </Link>
        . Accessed 14 May 2024
      </Typography>
    </>
  ];
  return (
    <Stack gap={6}>
      <ContentCardSubSection title="COVID-19 and Mental Health">
        <ContentCardTextBlock
          title="What are the impacts of COVID-19 on mental health?"
          titleProps={{ fontWeight: 700, variant: "h5" } as TypographyProps}
        >
          <Typography maxWidth="90ch">
            The COVID-19 pandemic has had a negative impact on mental health throughout the United
            States. The CDC reported that US adults reported elevated levels of adverse mental
            health conditions, substance use, and suicidal ideation as a result of the COVID-19
            pandemic. <sup>2</sup>
          </Typography>
        </ContentCardTextBlock>

        <Typography maxWidth="90ch">
          CDC analyses of high school students found that, in 2021, more than a third (37%) of high
          school students reported they experienced poor mental health during the COVID-19 pandemic,
          and 44% reported they persistently felt sad or hopeless during the past year.
          <sup>3</sup>
        </Typography>
      </ContentCardSubSection>
      <ResourcesAndAttributions resources={resources} attributions={attributions} />
      <Grid item xs={12} lg={12} sx={{ maxWidth: "90ch" }}>
        <ContentCardSectionTitle title="Footnotes" />
        <List
          sx={{ listStyle: "decimal", pl: 2, mt: 0, pt: 0, fontSize: "12px", mr: 0 }}
          component="ol"
        >
          {footnotes.map((footnote, index) => {
            return (
              <ListItem key={index} sx={{ display: "list-item" }}>
                <ListItemText>{footnote}</ListItemText>
              </ListItem>
            );
          })}
        </List>
      </Grid>
      <AdditionalInfoPrivacyContent />
    </Stack>
  );
};

export default MentalHealthSubstanceUseAdditionalInfo;
