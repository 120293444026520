import MenuIcon from "@mui/icons-material/Menu";
import { Button } from "@mui/material";

interface Props {
  handleClick: VoidFunction;
}

const MenuButton: React.FC<Props> = ({ handleClick }) => (
  <Button color="dark" startIcon={<MenuIcon />} onClick={() => handleClick()}>
    Menu
  </Button>
);

export default MenuButton;
