import { ReactNode } from "react";
import { Divider, Stack, Typography } from "@mui/material";

interface PopoverContentProps {
  title: string;
  icon?: ReactNode;
  textContent?: string;
  children?: ReactNode;
}

const PopoverContent: React.FC<PopoverContentProps> = ({ title, icon, textContent, children }) => {
  return (
    <Stack gap={1} p={2}>
      <Stack direction="row" alignItems="center" gap={1}>
        {icon}
        <Typography variant="h6">{title}</Typography>
      </Stack>
      <Divider />
      {textContent && <Typography sx={{ maxWidth: "50ch" }}>{textContent}</Typography>}
      {children}
    </Stack>
  );
};

export default PopoverContent;
